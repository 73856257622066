<script>
import ProductTile from "@/components/ProductTile.vue";
import StepChoice from "@/components/templates/StepChoice.vue";
import { mapGetters } from "vuex";

export default {
  name: "ContainerStep",
  components: {
    ProductTile,
    StepChoice,
  },
  props: {
    step: {
      type: Object,
      required: true,
    },
    selectedProducts: {
      type: Array,
      required: true,
    },
    startingOpen: {
      type: Boolean,
      required: false,
    },
    displayDivider: {
      type: Boolean,
      required: true,
    },
    openOverride: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      products: this.selectedProducts,
      open: this.startingOpen,
      seen: false,
      status: {},
    };
  },
  mounted() {
    this.updateStepValidityStatus();

    this.open = this.openOverride;
  },
  methods: {
    /**
     * Toggles a product in our step choices
     *
     * @param {*} product to toggle
     */
    toggleProduct: function (product) {
      const productAlreadySelected = this.products.some((p) => p.id === product.id);

      // If the product doesn't already have a type
      if (!product.type) {
        // Set default
        product.type = "product";
      }
      // If we are dealing with a choice
      else if (product.type === "choice") {
        // Attach the question to the choice
        product.category = this.step.header;
      }

      // Attaching parentStep and parentStepName to the product
      product.parentStep = this.step.id;
      product.parentStepName = this.step.header;

      // If the product was already selected
      if (productAlreadySelected) {
        const existingProductIndex = this.products.findIndex((p) => p.id === product.id);
        const productQuantityDiffers =
          this.products[existingProductIndex].quantity != product.quantity && product.quantity > 0;

        if (productQuantityDiffers) {
          this.products[existingProductIndex] = product;
          // Triggering reactivity
          this.products = [...this.products];
          this.addProductsUpdateIfExists();
        } else {
          this.products = this.products.filter((p) => p.id != product.id);
          this.deselectProducts([product]);
        }
      }
      // If the product was not already selected
      else {
        // Set parent specific data

        // Add item to collection and update
        this.products.push(product);
        this.addProductsUpdateIfExists();
      }

      this.updateStepValidityStatus();
    },

    /**
     * Adds a product to the step choices, while also deselecting all other products
     *
     * @param {*} product to deselect
     */
    addProductDeselectOthers: function (product) {
      const productAlreadySelected = this.products.some((p) => p.id === product.id);

      this.deselectProducts();

      if (!productAlreadySelected) {
        product.parentStep = this.step.id;
        product.parentStepName = this.step.header;
        if (!product.type) {
          product.type = "product";
        } else if (product.type === "choice") {
          product.category = this.step.header;
        }
        this.products = [product];
        this.addProductsUpdateIfExists();
      }

      this.updateStepValidityStatus();
    },

    /**
     * Toggles the step drawer
     */
    toggleStepDrawer: function () {
      this.open = !this.open;
      this.seen = true;

      this.$emit("toggleOpenContainerId", this.step.id);

      if (this.step.isOptional) {
        this.updateStepValidityStatus();
      }
    },

    /**
     * Deselects provided products (all if none provided) from the choices already made on this step
     *
     * @param {*} products
     */
    deselectProducts: function (products = []) {
      const idsToDeselect = products.map((p) => p.id);

      if (idsToDeselect.length > 0) {
        idsToDeselect.forEach((id) => {
          this.$emit("removeProduct", {
            id,
            parentStep: this.step.id,
          });
        });

        this.products = this.products.filter((p) => !idsToDeselect.includes(p.id));
      } else {
        this.products.forEach((product) => {
          this.$emit("removeProduct", product);
        });

        this.products = [];
      }
    },

    /**
     * Tells the parent component to add new products, also updates the data if it already exists
     */
    addProductsUpdateIfExists: function () {
      this.$emit("addProductsUpdateIfExists", {
        parentStep: this.step.id,
        products: this.products,
      });
    },

    /**
     * Updates the status object with the current validity status
     */
    updateStepValidityStatus: function () {
      const res = {
        state: null,
        msg: "Dette seksjonen har ikke blitt åpnet enda.",
      };

      // If the step is optional
      if (this.step.isOptional) {
        res.state = "approved";
        res.msg = "Denne seksjonen oppfyller alle krav.";
        this.$emit("updateStepValidity", true);
      }
      // Product Selection Template
      else if (this.step.templateName === "product-selection" || this.step.templateName === "choice") {
        // If the step is multiple choice
        if (this.multipleValuesIsAllowed) {
          // Check if one or more products is chosen
          const oneOrMoreProductsIsChosen = this.products.length > 0;

          // If there is one or more products chosen
          if (oneOrMoreProductsIsChosen) {
            // Set state and emit validity
            res.state = "approved";
            res.msg = "Denne seksjonen oppfyller alle krav.";
            this.$emit("updateStepValidity", true);
          }
          // If the step haven't been opened yet
          else if (!this.seen) {
            // Set state and emit validity
            res.state = null;
            res.msg = "I denne seksjonen må det velges minimum ett produkt.";
            this.$emit("updateStepValidity", false);
          }

          // If there are no products and the step has been seen
          else {
            // Set state and emit validity
            res.state = "error";
            res.msg = "I denne seksjonen må det velges minimum ett produkt.";
            this.$emit("updateStepValidity", false);
          }
        }

        // If the step is single choice
        else {
          // Check if a product is chosen
          const productIsChosen = this.products.length === 1;

          // If a product is chosen
          if (productIsChosen) {
            // Set state and emit validity
            res.state = "approved";
            res.msg = "Denne seksjonen oppfyller alle krav.";
            this.$emit("updateStepValidity", true);
          }
          // If the step haven't been opened yet
          else if (!this.seen) {
            // Set state and emit validity
            res.state = null;
            res.msg = "I denne seksjonen må det velges ett enkelt produkt.";
            this.$emit("updateStepValidity", false);
          }

          // If there are no products chosen and the step has already been seen
          else {
            // Set state and emit validity
            res.state = "error";
            res.msg = "Du må velge ett produkt.";
            this.$emit("updateStepValidity", false);
          }
        }
      }

      this.status = res;
    },

    productAvailable: function (product) {
      if (product.filterItemIds.length > 0) {
        const filtersMatch = [];

        product.filterItemIds.forEach((filter) => {
          let filterItemFound = false;

          this.choices.forEach((choice) => {
            if (choice.products.filter((product) => product.id === filter).length > 0) {
              filterItemFound = true;
            }
          });

          filtersMatch.push(filterItemFound);
        });

        if (product.allFiltersMustApply) {
          return filtersMatch.every((match) => match === true);
        } else {
          return filtersMatch.some((match) => match === true);
        }
      } else {
        return true;
      }
    },
  },
  computed: {
    stepAvailable: function () {
      // Default availability state
      let available = true;
      // Collection of products with filter
      const stepProductsWithFilter = this.sortedStepItems.filter((product) => product.filterItemIds.length > 0);

      // If there are any products with a filter
      if (stepProductsWithFilter.length > 0) {
        // Default matchcount
        let matches = 0;

        // Iterate all products
        for (let i = 0; i < stepProductsWithFilter.length; i++) {
          // Iterate all filters on product
          for (let j = 0; j < stepProductsWithFilter[i].filterItemIds.length; j++) {
            //
            if (this.sortedStepItems.some((p) => p.id === stepProductsWithFilter[i].filterItemIds[j])) {
              matches++;
            }

            // If we can find a product with this id
            else if (this.$store.getters.productExistInChoices(stepProductsWithFilter[i].filterItemIds[j])) {
              // Increase matchcount
              matches++;
            }
          }
        }

        // If we have any matches at all, return true
        return matches > 0;
      }

      // Return availability state
      return available;
    },
    stepInfo: function () {
      const settings = [];
      if (this.step.isOptional) {
        settings.push("Valgfritt");
      }

      if (this.step.multipleValuesIsAllowed) {
        settings.push("Flervalg");
      } else {
        settings.push("Enkeltvalg");
      }

      return settings.join(", ");
    },
    totalProducts: function () {
      return this.products.map((p) => p.quantity)?.reduce((total, value) => total + value, 0);
    },
    sortedStepItems: function () {
      const items = this.step.items;
      return items.sort((a, b) => {
        if (a.orderSortValue < b.orderSortValue) {
          return -1;
        }
        if (a.orderSortValue > b.orderSortValue) {
          return 1;
        }
        return 0;
      });
    },
    availableProducts: function () {
      return this.sortedStepItems.filter((product) => this.productAvailable(product));
    },
    ...mapGetters(["choices"]),
  },
  watch: {
    openOverride: {
      handler(isOpen) {
        this.open = isOpen;
      },
    },
    selectedProducts: {
      handler(products) {
        this.products = products;
      },
    },
  },
};
</script>

<style>
.animate {
  transition-property: all;
  transition-duration: 0.3s;
  transition-delay: 0;
}

.animate.focus {
  padding-left: 2rem;
  position: relative;
}

.select-pointer {
  position: relative;
  overflow: hidden;
}

.select-pointer:before {
  transition-property: all;
  transition-duration: 0.3s;
  transition-delay: 0;
  content: ">";
  position: absolute;
  left: -1rem;
}

.select-pointer.focus:before {
  left: 0.5rem;
}

.remove-padding {
  padding-bottom: 0;
}

.productSelection-enter-active,
.productSelection-leave-active {
  transition: all 0.5s ease;
}
.productSelection-enter-from,
.productSelection-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>

<template>
  <v-expand-transition>
    <v-col v-if="stepAvailable" class="d-flex flex-column items-center py-0" style="">
      <!-- Step Header -->
      <v-hover v-slot="{ hover }">
        <div class="d-flex justify-space-between" role="button" @click="toggleStepDrawer()">
          <span
            class="animate select-pointer text-h7 py-3"
            :class="{ focus: hover || open, 'font-weight-medium': open }"
          >
            {{ step.header }}
            <small v-if="step.isOptional" class="text-caption"
              ><em>({{ stepInfo }})</em></small
            >
          </span>

          <div class="d-flex align-center" style="gap: 1rem">
            <small v-if="step.templateName != 'step-choice'"
              >{{ totalProducts }} {{ totalProducts === 1 ? "produkt" : "produkter" }} valgt
            </small>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-if="!status.state" color="warning" v-on="on"> mdi-help-circle-outline </v-icon>
                <v-icon v-else-if="status.state === 'error'" color="error" v-on="on"> mdi-alert-circle-outline </v-icon>
                <v-icon v-else-if="status.state === 'approved'" color="success" v-on="on"> mdi-check </v-icon>
              </template>
              <span v-if="status.msg">{{ status.msg }}</span>
            </v-tooltip>
          </div>
        </div>
      </v-hover>

      <!-- Step Drawer -->
      <v-expand-transition>
        <v-row v-if="open" class="ma-0">
          <!-- Step description -->
          <v-col v-if="step.description2" cols="12">
            <v-alert type="error" icon="mdi-information" class="remove-padding" outlined
              ><span v-html="step.description2"></span>
            </v-alert>
          </v-col>

          <!-- Product Selection Template -->
          <template v-if="step.templateName === 'product-selection'">
            <v-slide-x-reverse-transition group hide-on-leave class="d-flex flex-wrap" style="width: 100%">
              <v-col
                v-for="product in availableProducts"
                :key="`containerStep_${product.productId}`"
                sm="6"
                md="4"
                lg="3"
                xl="2"
                class="py-8"
              >
                <!-- Multiple Choice -->
                <template v-if="step.multipleValuesIsAllowed">
                  <product-tile
                    :key="`container_step_product_${product.id}`"
                    :product="product"
                    :amountSelection="false"
                    :chosen="products.some((p) => p.id === product.id)"
                    :initialValue="products.find((p) => p.id === product.id)?.quantity"
                    @productSelected="(product) => toggleProduct(product)"
                    transparent
                  />
                </template>

                <!-- Single Choice -->
                <template v-else>
                  <product-tile
                    :key="`container_step_product_${product.id}`"
                    :product="product"
                    :amountSelection="false"
                    :chosen="products.some((p) => p.id === product.id)"
                    @productSelected="(product) => addProductDeselectOthers(product)"
                    transparent
                  />
                </template>
              </v-col>
            </v-slide-x-reverse-transition>
          </template>

          <!-- Step Choice Template -->
          <v-col
            cols="12"
            v-if="step.templateName === 'step-choice'"
            class="px-3 py-8 d-flex justify-space-around flex-wrap"
          >
            <step-choice
              v-for="choice in step.productBuilderHiddenSteps"
              :choiceData="choice"
              :key="choice.id"
              :chosen="products.some((c) => c.id === choice.id)"
              @choiceSelected="(choice) => addProductDeselectOthers(choice)"
            ></step-choice>
          </v-col>

          <!-- DEBUG -->
          <!-- <v-container class="text-left primary darken-2 white--text pa-0 my-12">
            <h4 class="pa-5 primary darken-4">ContainerStep.vue</h4>
            <pre class="pa-5">{{ step.items.filter((product) => productAvailable(product)) }}</pre>
          </v-container> -->
        </v-row>
      </v-expand-transition>

      <v-divider v-if="displayDivider" class=""></v-divider>
    </v-col>
  </v-expand-transition>
</template>
