var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticStyle:{"z-index":"1"},style:({ background: _vm.$vuetify.theme.themes[_vm.theme].background })},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","md":"2"}},[((this.$route.name === 'Steps' && this.$route.params.step > 1) || this.$route.name === 'Summary')?[_c('v-fade-transition',{attrs:{"appear":""}},[_c('v-btn',{staticClass:"headerLink",attrs:{"ripple":false,"text":"","to":_vm.backUrl}},[_c('v-icon',{staticClass:"mr-2 icon"},[_vm._v("fal fa-arrow-left")]),_c('span',[_vm._v("Tilbake")])],1)],1)]:_vm._e()],2),_c('v-col',{staticClass:"d-flex flex-column align-center justify-center",attrs:{"cols":"12","md":"8"}},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Canes Logo","contain":"","src":require("./assets/logo.png"),"height":"150"}})],1)],1),_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","md":"2"}},[_c('v-fade-transition',{attrs:{"appear":""}},[(this.$route.name != 'Home')?_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"headerLink",attrs:{"ripple":false,"text":""},on:{"click":function($event){_vm.$store.dispatch('clearChoices');
                _vm.$router.push('/');}}},[_c('v-icon',{staticClass:"mr-2 icon"},[_vm._v("fal fa-times")]),_c('span',[_vm._v("Avslutt")])],1)],1):_vm._e()])],1)],1)],1),(_vm.alert)?_c('v-alert',{staticClass:"mx-10",attrs:{"dense":"","type":"error"}},[_vm._v(" App ble startet uten kunde-id, priser og ordre vil dermed ikke fungere. ")]):_vm._e(),(_vm.isLoading)?_c('v-container',{staticClass:"text-center",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"fill-height d-flex flex-column align-center",attrs:{"align-content":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":70,"color":"primary"}}),_c('span',{staticClass:"mt-5"},[_vm._v("Laster data")])],1)],1):_c('v-main',[_c('v-fade-transition',{attrs:{"appear":"","hide-on-leave":""}},[_c('router-view',{key:_vm.$route.path,on:{"reload":function($event){return _vm.reload()}}})],1)],1),_c('v-slide-y-reverse-transition',[(_vm.projectName)?_c('div',{staticClass:"d-flex justify-space-around text-uppercase font-weight-bold grey--text text--lighten-2 px-5 pb-2",class:{
        'text-h1': _vm.projectName.length < 20,
        'text-h6 text-sm-h5 text-lg-h2': _vm.projectName.length < 40,
        'text-h7 text-sm-h6 text-lg-h4': _vm.projectName.length >= 40,
      },staticStyle:{"position":"absolute","width":"100vw","z-index":"-1","bottom":"0"}},_vm._l((_vm.projectName),function(char,index){return _c('span',{key:`char_${index}_${char}`},[_vm._v(_vm._s(char))])}),0):_vm._e()]),(_vm.god)?_c('v-container',{staticClass:"d-flex align-center"},[_c('v-switch',{staticClass:"mx-5",attrs:{"hint":"Because you're worth it!","inset":"","label":"Darkmode","persistent-hint":""},model:{value:(_vm.darktheme),callback:function ($$v) {_vm.darktheme=$$v},expression:"darktheme"}}),_c('v-btn',{staticClass:"mx-5",attrs:{"color":"primary"},on:{"click":function($event){_vm.$store.dispatch('clearChoices');
        _vm.$router.push('/guide');}}},[_vm._v(" Clear All Choices ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }