import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

/**
 * Comparator used for sorting
 *
 * @param {*} a
 * @param {*} b
 * @returns
 */
function stepComparator(a, b) {
  if (a.orderSortValue < b.orderSortValue) {
    return -1;
  }
  if (a.orderSortValue > b.orderSortValue) {
    return 1;
  }
  return 0;
}

/**
 *
 * @param {Array} steps
 * @returns
 */
function recursivelyCollectAllProducts(steps) {
  let productsCollection = [];

  steps.forEach((step) => {
    if (step.items) {
      productsCollection = [...productsCollection, ...step.items];
    }

    if (step.productBuilderHiddenSteps) {
      productsCollection = [...productsCollection, ...recursivelyCollectAllProducts(step.productBuilderHiddenSteps)];
    }
  });

  return productsCollection;
}

/**
 *
 * @param {Array} steps
 * @returns
 */
function recursivelyCollectAllSteps(steps) {
  let stepCollection = [];

  steps.forEach((step) => {
    if (step.steps && step.steps.length > 0) {
      const deeperSteps = recursivelyCollectAllSteps(step.steps);

      stepCollection = [...stepCollection, ...deeperSteps];
    }

    if (step.productBuilderHiddenSteps && step.productBuilderHiddenSteps.length > 0) {
      const deeperSteps = recursivelyCollectAllSteps(step.productBuilderHiddenSteps);

      stepCollection = [...stepCollection, ...deeperSteps];
    }

    // const currentStep = Object.assign({}, step)

    // delete currentStep.steps;
    // delete currentStep.productBuilderHiddenSteps;
    stepCollection = [...stepCollection, ...[step]];
  });

  return stepCollection;
}

export const store = new Vuex.Store({
  state: {
    builder: {
      steps: [],
      addToShoppingCart: false,
    },
    allSteps: [],
    projectName: localStorage.getItem("projectName") || "",
    choices: [],
    friggProductDetails: {},
    customerid: localStorage.getItem("customerid") || undefined,
    builderid: localStorage.getItem("builderid") || 11,
    loadBuilderCompleted: false,
    compositions: [],
    choiceCache: {},
  },
  mutations: {
    /**
     * Adds a step to choices
     *
     * @param {Object} state
     * @param {Number} id
     */
    addStep(state, id) {
      let stepInfo = state.allSteps.filter((obj) => obj.id === id);

      if (stepInfo.length < 1) {
        console.error("Could not find step with step id " + id);
      }

      let step = {
        name: stepInfo[0].header,
        id: id,
        products: [],
        steps: [],
        additionalInfo: {},
        compositionCollection: [],
        seenBefore: false,
        stepValid: false,
        template: stepInfo[0].templateName,
        orderSortValue: stepInfo[0].orderSortValue,
      };
      let ls = JSON.parse(localStorage.getItem("choices")) || [];
      ls.push(step);
      // ls.sort(stepComparator);
      localStorage.setItem("choices", JSON.stringify(ls));
      state.choices = ls;
    },

    /**
     * Adds a step to choices
     *
     * @param {Object} state
     * @param {Object} param1
     */
    addStepAtIndex(state, { step, index }) {
      let stepInfo = state.allSteps.filter((s) => s.id === step.id);

      if (stepInfo.length < 1) {
        console.error("Could not find step with step id " + step.id);
        return;
      }

      if (index != 0 && index > state.choices.length - 1) {
        console.error("Index out of bounds");
        return;
      }

      const newStep = {
        name: stepInfo[0].header,
        id: step.id,
        products: [],
        steps: [],
        additionalInfo: {},
        compositionCollection: [],
        seenBefore: false,
        stepValid: false,
        template: stepInfo[0].templateName,
        orderSortValue: stepInfo[0].orderSortValue,
      };

      let ls = JSON.parse(localStorage.getItem("choices")) || [];

      if (ls.length > 0) {
        ls.splice(index, 0, newStep);
      } else {
        ls.push(newStep);
      }

      localStorage.setItem("choices", JSON.stringify(ls));
      state.choices = [...ls];
    },

    /**
     * Removes a step from choicesv
     *
     * @param {Object} state
     * @param {Number} id
     */
    removeStep(state, id) {
      let ls = JSON.parse(localStorage.getItem("choices"));
      ls = ls.filter((obj) => obj.id !== id);
      ls.sort(stepComparator);
      localStorage.setItem("choices", JSON.stringify(ls));
      state.choices = state.choices.filter((obj) => obj.id !== id);
      state.choices.sort(stepComparator);
    },

    /**
     * Removes given step ids from choices
     *
     * @param {Object} state
     * @param {Array} steps
     */
    removeSteps(state, steps) {
      state.choices = state.choices.filter((s) => !steps.includes(s.id));
      localStorage.setItem("choices", JSON.stringify(state.choices));
    },

    /**
     * Toggle a step
     *
     * @param {Object} state
     * @param {Number} stepId
     */
    toggleStep(state, stepId) {
      const stepIndex = state.choices.findIndex((choice) => choice.id === stepId);

      // If the step exists as a choice
      if (stepIndex != -1) {
        // Remove it
        state.choices.splice(stepIndex, 1);
      }
      // If the step dosen't exist as a choice
      else {
        state.choices.push({
          name: null,
          id: stepId,
          template: null,
          products: [],
          steps: [],
          additionalInfo: {},
          compositionCollection: [],
          seenBefore: false,
          stepValid: false,
        });
      }

      localStorage.setItem("choices", JSON.stringify(state.choices));
    },

    /**
     * Update step data
     *
     * @param {Object} state
     * @param {Object} param1
     */
    updateStepData(state, { stepIndex, stepChoices }) {
      if (stepIndex > state.choices.length - 1) {
        state.choices.push(stepChoices);
      } else {
        state.choices[stepIndex] = stepChoices;
      }

      state.choices = [...state.choices];
      localStorage.setItem("choices", JSON.stringify(state.choices));
    },

    /**
     * Sets the customer id
     *
     * @param {Object} state
     * @param {Number} id
     */
    setCustomerid(state, id) {
      localStorage.setItem("customerid", id);
      state.customerid = id;
    },

    /**
     * Sets the customer id
     *
     * @param {Object} state
     * @param {Number} id
     */
    setBuilderid(state, id) {
      localStorage.setItem("builderid", id);
      state.builderid = id;
    },

    /**
     * Sets the all steps data
     *
     * @param {Object} state
     * @param {Array} choices
     */
    setSteps(state, choices) {
      let sorted = [];

      // Reducing down all steps to ids
      let ids = state.builder.steps.reduce((list, step) => {
        list.push(step.id);
        return list;
      }, []);

      // Iterating over each id
      ids.forEach((id) => {
        // If it exists and a choice, then store it
        if (choices.indexOf(id) != -1) sorted.push(id);
      });

      // Storing sorted steps
      localStorage.setItem("choices", JSON.stringify(sorted));
      state.choices = sorted;
    },

    /**
     * Sets the builder state with the newest productbuilder api data
     *
     * @param {Object} state
     * @param {Object} payload
     */
    setBuilder(state, payload) {
      let steps = payload.steps;

      // Mapping up the different main product ids
      let stepItemIds = steps
        .map((step) => step.items)
        .reduce((arr, val) => arr.concat(val))
        .map((item) => item.productId);

      // Keeping track of the items to remove
      let itemsToRemove = [];

      // Iterate over all variants of all products
      steps.forEach((step, stepIndex) => {
        step.items.forEach((item) => {
          item.variantChildren.forEach((variant) => {
            // Setting the index of each variant
            let itemIndex = stepItemIds.indexOf(variant.productId);

            // If it exists
            if (itemIndex != -1) {
              // Add it to the removal array
              itemsToRemove.push([stepIndex, itemIndex]);
            }
          });
        });
      });

      // Iterating over each of the products set for removal
      for (let i = itemsToRemove.length - 1; i >= 0; i--) {
        // Removing the product
        steps[itemsToRemove[i][0]].items.splice(itemsToRemove[i][1], 1);
      }

      // Storing the finished data
      payload.steps = steps;
      localStorage.setItem("builder", JSON.stringify(payload));
      state.builder = payload;
      state.allSteps = recursivelyCollectAllSteps(payload.steps);
      state.loadBuilderCompleted = true;
    },

    /**
     * Set the product details from Frigg, as well as updating localStorage.
     *
     * @param {Object} state
     * @param {Object} payload
     */
    setFriggProductDetails(state, payload) {
      localStorage.setItem("friggProductDetails", JSON.stringify(payload));
      state.friggProductDetails = payload;
    },

    /**
     * Initializes store with data from localStorage if it exists, otherwise set it to the current state.
     *
     * @param {Object} state
     */
    initializeStore(state) {
      state.choices = JSON.parse(localStorage.getItem("choices")) ?? state.choices;
      state.builder = JSON.parse(localStorage.getItem("builder")) ?? state.builder;
      state.compositions = JSON.parse(localStorage.getItem("compositions")) ?? state.compositions;
      state.friggProductDetails = JSON.parse(localStorage.getItem("friggProductDetails")) ?? state.friggProductDetails;
    },

    /**
     *
     * @param {Object} state
     * @param {String} name
     */
    setProjectName(state, name) {
      localStorage.setItem("projectName", name);
      state.projectName = name;
    },

    /**
     *
     * @param {Object} state
     * @param {*} composition
     */
    addCompositionUpdateIfExists(state, composition) {
      const compositionIndex = state.compositions.findIndex((c) => c.id === composition.id);

      if (compositionIndex > -1) {
        state.compositions.splice(compositionIndex, 1, composition);
      } else {
        state.compositions.push(composition);
      }

      localStorage.setItem("compositions", JSON.stringify(state.compositions));
    },

    /**
     *
     * @param {Number} id
     */
    removeComposition(state, id) {
      state.compositions = state.compositions.filter((composition) => composition.id != id);

      localStorage.setItem("compositions", JSON.stringify(state.compositions));
    },

    setChoiceProducts(state, { choiceId, products }) {
      const choiceIndex = state.choices.findIndex((c) => c.id === choiceId);

      if (choiceIndex > -1) {
        state.choices[choiceIndex].products = products;
      }
    },

    storeChoiceCache(state, { id, data }) {
      state.choiceCache[id] = data;
    },

    // restoreChoiceCache(state, { idx, data }) {
    //   state.choices[idx] = data;
    // }
  },
  getters: {
    choices: (state) => state.choices,
    builder: (state) => state.builder,
    friggProductDetails: (state) => state.friggProductDetails,
    customerid: (state) => state.customerid,
    builderid: (state) => state.builderid,
    productExistInChoices: (state) => (id) => {
      const choicesThatIncludeProduct = state.choices.filter((choice) =>
        choice.products.find((product) => product.id === id)
      );
      return choicesThatIncludeProduct.length > 0;
    },
    projectName: (state) => state.projectName,
    allSteps: (state) => state.allSteps,
    loadBuilderCompleted: (state) => state.loadBuilderCompleted,
    compositions: (state) => state.compositions,
    choiceCache: (state) => state.choiceCache,
    addToShoppingCart: (state) => state.builder.addToShoppingCart,
  },
  actions: {
    // Method to clear all choices made
    clearChoices() {
      store.state.choices = [];
      localStorage.setItem("choices", JSON.stringify([]));

      store.state.projectName = "";
      localStorage.setItem("projectName", "");

      store.state.compositions = [];
      localStorage.setItem("compositions", JSON.stringify([]));

      store.state.choiceCache = {};
      localStorage.setItem("choiceCache", JSON.stringify({}));
    },

    // Method to fetch all remove data
    async getRemoteData({ state, commit }) {
      let builder = await axios.get(`https://pb.friggcms.no/api/productbuilders/${state.builderid}`);
      // let builder = await axios.get(
      //   `https://localhost:5001/api/productbuilders/${state.builderid}`
      // );
      commit("setBuilder", builder.data);

      // Fetching all products from steps
      let stepItems = recursivelyCollectAllProducts(builder.data.steps);

      // Getting all the main product sku's
      let mainItems = stepItems.map((product) => product.productId);
      let optionalItems = [];

      let productsWithOptionalItems = stepItems
        .map((product) => product.variantChildren)
        .filter((product) => product.length > 0);

      // Getting all the optional product sku's
      if (productsWithOptionalItems.length > 0) {
        optionalItems = productsWithOptionalItems
          .reduce((total, current) => total.concat(current))
          .map((product) => String(product.productId));
      }

      // Joining it all up and forming the api call
      let products = [...new Set(["", ...mainItems, ...optionalItems])];

      let fetchProducts = await axios.get(
        `https://api.v4.friggcms.no/api/v4/products?CustomerID=${
          state.customerid
        }&QueryType=bySkuList&ExpandAttributes=true&ExpandStructures=true${products.join("&FilterSkus=")}`,
        {
          headers: {
            apikey: "valhalla12---A781024-CD2F-4692-D936-875B796A1B53",
          },
        }
      );

      commit("setFriggProductDetails", fetchProducts.data.products);
    },

    async updateCart(store, products) {
      let headers = {
        apikey: "valhalla12---A781024-CD2F-4692-D936-875B796A1B53",
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `https://api.v4.friggcms.no/api/v4/cart/addtocart?CartId=customer_${store.state.customerid}&AppendToExistingIfExists=false&ReplaceExistingIfExists=false&IgnoreIfExists=false`,
        JSON.stringify(products),
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        setTimeout(() => {
          window.location = "https://canes.no/handlevogn";
        }, 500);
      } else {
        console.error(`Error ${response.status}: Could not add to cart.`);
      }
    },

    async getCart(store) {
      let headers = {
        apikey: "valhalla12---A781024-CD2F-4692-D936-875B796A1B53",
      };

      let response = await axios.get(`https://api.v4.friggcms.no/api/v4/cart?Id=customer_${store.state.customerid}`, {
        headers: headers,
      });

      if (response.status === 200) {
        return response.data;
      } else {
        console.error(`Error ${response.status}: Could not get cart.`);
      }
    },

    async addToCart(store, products) {
      let headers = {
        apikey: "valhalla12---A781024-CD2F-4692-D936-875B796A1B53",
        "Content-Type": "application/json",
      };

      let response = await axios.post(
        `https://api.v4.friggcms.no/api/v4/cart/addtocart?CartId=customer_${store.state.customerid}&AppendToExistingIfExists=false&ReplaceExistingIfExists=false&IgnoreIfExists=false`,
        JSON.stringify(products),
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        console.error(`Error ${response.status}: Could not add to cart.`);
      }
    },

    async sendRequest(store) {
      const headers = {
        apikey: "valhalla12---A781024-CD2F-4692-D936-875B796A1B53",
        "Content-Type": "application/json",
      };

      const payload = {
        customer: {
          id: store.state.customerid,
        },
        status: "offer",
        deliveryMethod: {
          title: "Frakt"
        },
        paymentMethod: {
          title: "Faktura"
        }
      };

      let response = await axios.post(`https://api.v4.friggcms.no/api/v4/orders?fromCartId=customer_${store.state.customerid}`, JSON.stringify(payload), {
        headers: headers,
      });

      if (response.status === 200) {
        return response.data;
      } else {
        console.error(`Error ${response.status}: Could not send order.`);
      }
    },

    async storeComposition(store, composition) {
      let headers = {
        apikey: "valhalla12---A781024-CD2F-4692-D936-875B796A1B53",
        "Content-Type": "application/json",
      };

      const metaValue = { project: store.state.projectName, ...composition };

      const meta = {
        customerId: store.state.customerid,
        metaKey: composition.id,
        metaValue: JSON.stringify(metaValue),
      };

      let response = await axios.post(
        `https://api.v4.friggcms.no/api/v4/customers/meta?updateIfExists=true`,
        JSON.stringify(meta),
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        console.log("Composition stored.");
      } else {
        console.error(`Error ${response.status}: Could not add to cart.`);
      }
    },

    async deleteComposition(store, id) {
      let headers = {
        apikey: "valhalla12---A781024-CD2F-4692-D936-875B796A1B53",
      };

      let response = await axios.delete(
        `https://api.v4.friggcms.no/api/v4/customers/meta/${store.state.customerid}/${id}`,
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        console.log("Composition deleted.");
      } else {
        console.error(`Error ${response.status}: Could not add to cart.`);
      }
    },

    // Method to toggle products in /guide/
    toggleProduct(store, data) {
      let parent = store.state.choices[data.parentChoice];

      // If the parent choice doesn't have any products associated with yet, we create an empty array
      if (!parent.products) {
        parent.products = [];
      }

      // Try to find product
      const productIndex = parent.products.findIndex((product) => product.id === data.id);

      // If the product exists
      if (productIndex != -1) {
        // If the product has a positive amount
        if (parent.products[productIndex].amount > 0) {
          // Set the product amount to 0
          parent.products[productIndex].amount = 0;
        } else {
          parent.products[productIndex].amount = 1;

          // REMOVED, BUT BE ATTENDED TO MAKE V1 WORK
          // we push the chosen id directly into the array
          // parent.products.push({ id: data.id, amount: 1 });
        }

        // Triggering reactivity
        parent.products.push({});
        parent.products.pop();
      } else {
        // If not, we remove the item from the array (this is v1 functionality)
        let spliceIndex = parent.products.map((item) => item.id).indexOf(data.id);
        parent.products.splice(spliceIndex, 1);
      }

      // Finally we store our changes
      localStorage.setItem("choices", JSON.stringify(store.state.choices));
    },

    // Method to sort our steps in the correct order
    sortSteps() {
      store.state.choices.sort(stepComparator);
    },

    // Method to update step data from product cards
    updateStepData(store, data) {
      // Getting the product index
      let index = store.state.choices[data[0]].products.findIndex((product) => product.id === data[1].id);

      // If the product doesn't exist in choice
      if (index === -1) {
        // Add chosen product
        store.state.choices[data[0]].products.push({ ...data[1] });
        localStorage.setItem("choices", JSON.stringify(store.state.choices));
      }
      // If product does exist in choice
      else {
        // Add chosen product
        store.state.choices[data[0]].products[index] = { ...data[1] };
        localStorage.setItem("choices", JSON.stringify(store.state.choices));
      }
    },

    // Method to update step sub product data from product cards
    updateStepSubProductData(store, data) {
      const stepProducts = store.state.choices[data[0]].products;
      const masterIndex = stepProducts.findIndex((p) => p.id === data[2]);
      const chosenProducts = stepProducts[masterIndex].chosenProducts;

      // Getting the product index
      const index = chosenProducts.findIndex((product) => product.id === data[1].id);

      // If the product doesn't exist in choice
      if (index === -1) {
        // Add chosen product
        chosenProducts.push({ ...data[1] });
        localStorage.setItem("choices", JSON.stringify(store.state.choices));
      }
      // If product does exist in choice
      else {
        // Add chosen product
        chosenProducts[index] = { ...data[1] };
        localStorage.setItem("choices", JSON.stringify(store.state.choices));
      }

      // Trigger reactivity
    },

    // Method to update the products from the summary
    updateProducts(store, data) {
      let index, product;

      // Main products
      if (data[1].type === "product") {
        index = store.state.choices.findIndex((choice) => choice.id === data[0]);
        product = store.state.choices[index].products.filter((product) => product.id === data[1].id);

        product[0].amount = data[1].value;

        // Trigger reactivity
        product[0].changed = Math.random();
        store.state.choices[index].changed = Math.random();
      }

      // Variants - also known as product specific additions
      if (data[1].type === "variant") {
        index = store.state.choices.findIndex((choice) => choice.id === data[0]);
        product = store.state.choices[index].products.filter((product) => product.id === data[1].id);

        product[0][data[1].variantType].amount = data[1].value;

        // Trigger reactivity
        product[0].changed = Math.random();
        store.state.choices[index].changed = Math.random();
      }

      // Optional products, also known as tilleggsvalg
      if (data[1].type === "optional") {
        index = store.state.choices.findIndex((choice) => choice.id === data[1].parent);
        product = store.state.choices[index].products.filter((product) => product.id === data[1].id);

        product[0].amount = data[1].value;

        // Trigger reactivity
        product[0].changed = Math.random();
        store.state.choices[index].changed = Math.random();
      }

      // Breakage
      if (data[1].type === "breakage") {
        index = store.state.choices.findIndex((choice) => choice.id === data[0]);
        product = store.state.choices[index].products.filter((product) => product.id == data[1].id);

        product[0].breakage[data[1].parent] = data[1].value;

        // Trigger reactivity
        product[0].changed = Math.random();
        store.state.choices[index].changed = Math.random();
      }

      // Save to localStorage
      localStorage.setItem("choices", JSON.stringify(store.state.choices));
    },

    // Method to remove a single product from a choice
    removeProduct(store, data) {
      let index = store.state.choices.findIndex((choice) => choice.id === (data[1].parent || data[0]));
      let productIndex = store.state.choices[index].products.findIndex((product) => product.id === data[1].id);

      // We set the amount to 0 in order to keep optional products
      store.state.choices[index].products[productIndex].amount = 0;

      // Trigger reactivity
      store.state.choices[index].changed = Math.random();
      store.state.choices[index].products[productIndex].changed = Math.random();

      // Save to localStorage
      localStorage.setItem("choices", JSON.stringify(store.state.choices));
    },

    addToChoiceCache(store, { cacheId, choiceId }) {
      const choiceIndex = store.state.choices.findIndex((c) => c.id === choiceId);

      if (choiceIndex > -1) {
        // store.state.choiceCache[cacheId] = JSON.parse(JSON.stringify(store.state.choices[choiceIndex]));
        this.commit("storeChoiceCache", {
          id: cacheId,
          data: JSON.parse(JSON.stringify(store.state.choices[choiceIndex])),
        });
      } else {
        console.warn("Choice does not exist, cannot cache");
      }
    },
  },
});
