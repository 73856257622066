<script>
export default {
  name: "NumberInput",

  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    increment: {
      type: [Number],
      required: false,
      default: 1,
    },
    startAtIncrement: {
      type: Boolean,
      required: false,
      default: false,
    },
    legend: {
      type: String,
      required: false,
      default: "Lengde",
    },
    index: {
      type: Number,
      required: false,
      default: -1,
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    colored: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: function () {
    return {
      mutatedValue: Number.isInteger(this.value) ? this.value : 0,
    };
  },

  created() {},

  computed: {},

  methods: {
    add: function () {
      this.mutatedValue += this.increment;
    },
    subtract: function () {
      if (this.mutatedValue > 0) this.mutatedValue -= this.increment;
    },
    clean: function () {
      this.mutatedValue = this.mutatedValue.toString().replace(/[^0-9]+/g, "");
    },
  },

  watch: {
    value: function (value) {
      this.mutatedValue = value;
    },

    mutatedValue: function (value) {
      if (Number.isInteger(value) && value > 0 && value % this.increment != 0) {
        if (value < this.increment) {
          this.mutatedValue = this.increment;
        } else {
          for (let i = value; i > 0; i--) {
            if (i % this.increment === 0) {
              this.mutatedValue = i;
              break;
            }
          }
        }
      }
    },
  },
};
</script>

<template>
  <v-text-field
    hide-details
    :label="legend"
    :outlined="!colored"
    dense
    :rounded="rounded"
    color="primary"
    @keydown="clean"
    v-model.number="mutatedValue"
    v-on:change="$emit('amountChanged', { value: mutatedValue, index: index })"
    :class="{
      'canes-coloring py-1': colored,
    }"
  >
    <!-- Add -->
    <v-icon class="icon" slot="append" :color="colored ? 'primary' : ''" x-small @click="add"> fa fa-plus </v-icon>

    <!-- Subtract -->
    <v-icon class="icon" slot="prepend-inner" :color="colored ? 'primary' : ''" x-small @click="subtract">
      fa fa-minus
    </v-icon>
  </v-text-field>
</template>

<style>
.v-input__prepend-inner,
.v-input__append-inner {
  align-self: center !important;
  margin: 0 !important;
}

.icon {
  width: 0.6rem;
}

.icon::after {
  display: none !important;
}

.numberInputContainer {
  display: flex;
  justify-content: space-between;
  /* border: 1px solid #eeeeee; */
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.canes-coloring .v-text-field__slot input {
  color: var(--v-primary-base);
}

.canes-coloring {
  border: 1px solid var(--v-primary-base);
}

button {
  padding: 0 1rem;
  outline: none;
  height: 100%;
  z-index: 2;
}

input {
  width: 100%;
  margin: 0;
  outline: none;
  padding: 0.5rem 0 0.5rem 1.5rem;
  text-align: center;
  z-index: 1;
}
</style>