<script>
import Hero from "@/components/Hero.vue";
import productHelper from "../mixins/productHelper.js";
import { mapGetters } from "vuex";

export default {
  name: "Summary",

  components: {
    Hero,
  },

  mixins: [productHelper],

  data() {
    return {
      steps: [],
      actionLoading: false,
      addToCartSuccess: false,
      sendRequestSuccess: false,
      friggProductDetails: {},
      stepTotal: [0],
      viewId: null,
      compositionIds: [],
      headers: [
        {
          text: "Produkt Id",
          align: "start",
          value: "productId",
        },
        { text: "Produkt", value: "name" },
        { text: "Antall", value: "quantity" },
      ],
    };
  },

  async beforeMount() {
    const cart = await this.$store.dispatch("getCart");
    if (cart.items.length > 0) {
      cart.items.forEach((item) => {
        if (item.sku === "KHEAD") {
          this.compositionIds.push(item.id);
        }
      });
    }
  },

  computed: {
    allItems: function () {
      const allItems = this.choices
        .filter((c) => c.products.length > 0 && !this.compositions.map((cp) => cp.parentStep).includes(c.id))
        .map((c) => c.products)
        .reduce((arr, val) => arr.concat(val), []);
      return allItems;
    },
    allProducts: function () {
      const products = this.allItems.filter((p) => p.quantity > 0);
      return products;
    },
    totalProductsLength: function () {
      const totalLength = this.allProducts.map((p) => p.quantity).reduce((total, quantity) => total + quantity, 0);
      return totalLength;
    },
    totalCompositionProducts: function () {
      const products = this.compositions
        .filter((c) => c.products.length > 0)
        .map((c) => c.products)
        .reduce((total, value) => total.concat(value), [])
        .map((p) => p.quantity)
        .reduce((total, value) => total + value, 0);
      return products;
    },
    allChoices: function () {
      const choices = this.allItems.filter((c) => c.type === "choice");
      return choices;
    },
    ...mapGetters(["compositions", "addToShoppingCart", "choices"]),
  },
  methods: {
    setViewId(viewId) {
      this.viewId = viewId;
    },
    async addToCart() {
      this.actionLoading = true;

      for (let i = 0; i < this.compositions.length; i++) {
        const cartProducts = [];
        const compositionQuantity = this.compositions[i].quantity || 1;

        const addCompositionHead = await this.$store.dispatch("addToCart", [
          {
            sku: "KHEAD",
            quantity: compositionQuantity,
            information: this.compositions[i].name,
            discount1: 0,
            discount2: 0,
            discount3: 0,
            discount4: 0,
            discountCodesInUse: "",
          },
        ]);

        const cartExcludingExistingCompositions = addCompositionHead.items.filter(
          (item) => item.sku === "KHEAD" && !this.compositionIds.includes(item.id)
        );

        if (cartExcludingExistingCompositions.length > 0) {
          this.compositionIds.push(cartExcludingExistingCompositions[0].id);

          this.compositions[i].products.forEach((product) => {
            cartProducts.push({
              sku: product.productId,
              quantity: product.quantity,
              information: this.compositions[i].name,
              mainStructProdId: cartExcludingExistingCompositions[0].id,
              discount1: 0,
              discount2: 0,
              discount3: 0,
              discount4: 0,
              discountCodesInUse: "",
            });
          });
        }

        await this.$store.dispatch("addToCart", cartProducts);
      }

      // Sort all products by productId
      this.allProducts.sort((a, b) => (a.productId > b.productId ? 1 : -1));

      const cartProducts = [];
      this.allProducts.forEach((product) => {
        cartProducts.push({
          sku: product.productId,
          quantity: product.quantity,
          discount1: 0,
          discount2: 0,
          discount3: 0,
          discount4: 0,
          discountCodesInUse: "",
        });
      });

      if (cartProducts.length > 0) {
          await this.$store.dispatch("addToCart", cartProducts);
      }
      
      if (this.addToShoppingCart) {
          this.addToCartSuccess = true;
        } else {
          await this.$store.dispatch("sendRequest");
          this.sendRequestSuccess = true;
        }

      this.actionLoading = false;

      this.$store.dispatch("clearChoices");

      setTimeout(() => {
        if (this.addToCartSuccess) {
          window.location = "https://canes.no/handlevogn";
        } else if (this.sendRequestSuccess) {
          window.location = "https://canes.no/";
        }
      }, 2000);
    },
  },
};
</script>

<style>
.v-divider {
  border-style: dashed !important;
}

.h-100 {
  height: 100% !important;
}

.sortable i {
  float: right;
}

.centered-line {
  height: 0px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px;
  border-style: dashed;
  border-color: var(--v-primary-base);
  flex-grow: 1;
}

.composition tr:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.composition,
.composition h4,
.composition i,
.composition .centered-line,
.composition-head,
.colored-border {
  transition: all 0.3s ease-in-out;
  /* transition: border-radius 0.075s ease-in-out; */
}

.composition.open .composition-head {
  transition: background-color, border-radius 0.075s ease-in-out;
}

.colored-border {
  border: 1px solid rgba(194, 37, 52, 0) !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.colored-left-border {
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 3px;
  border-style: solid;
  border-color: var(--v-primary-base);
}

.composition-head {
  border-radius: 8px;
}

.composition:hover .composition-head,
.composition.open .composition-head {
  background-color: var(--v-primary-base) !important;
}

.composition.open .composition-head {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.composition:hover h4,
.composition.open h4,
.composition:hover i,
.composition.open i,
.composition:hover .centered-line,
.composition.open .centered-line {
  color: var(--v-background-base) !important;
  border-color: var(--v-background-base) !important;
}

.composition:hover .colored-border,
.composition.open .colored-border {
  border: 1px solid rgba(194, 37, 52, 1) !important;
}

.composition:hover i {
  scale: 1.2;
}
</style>

<template>
  <v-container>
    <Hero
      v-if="addToCartSuccess"
      title="Takk!"
      subtitle="Du vil om kort tid bli videresendt til handlekurven din."
      class="mt-12"
    />
    <Hero
      v-else-if="sendRequestSuccess"
      title="Takk!"
      subtitle="Du vil om kort tid få svar på din forespørsel."
      class="mt-12"
    />

    <template v-else>
      <v-card class="mt-8">
        <v-card-title>Oppsummering</v-card-title>

        <v-card-text>
          <!-- Compositions -->
          <v-container v-if="compositions.length > 0" class="pa-0 mb-4">
            <h3 class="mb-2">Komposisjoner</h3>

            <v-container class="px-0 px-lg-5">
              <v-col
                v-for="(composition, index) in compositions"
                :key="`composition_${composition.id}`"
                cols="12"
                class="colored-left-border pt-0"
                :class="{ 'mt-4': index > 0 }"
              >
                <div class="d-flex justify-space-between align-center px-1">
                  <h4 class="primary--text">{{ composition.quantity }} x {{ composition.name }}</h4>
                  <span class="centered-line ml-4"></span>
                </div>
                <v-data-table
                  dense
                  hide-default-footer
                  :headers="headers"
                  :items="composition.products"
                  class="colored-border"
                ></v-data-table> </v-col
            ></v-container>
          </v-container>

          <!-- Products -->
          <v-container v-if="allProducts.length > 0" class="pa-0">
            <h3 class="mb-2">Produkter for hele prosjektet</h3>
            <v-container>
              <v-data-table
                dense
                hide-default-footer
                :headers="headers"
                :items="allProducts"
                class="px-0 px-lg-5"
              ></v-data-table>
            </v-container>
          </v-container>

          <v-container v-if="allProducts.length < 1 && compositions.length < 1" class="pa-0">
            <p>Det finnes ingen valgte produkter.</p>
          </v-container>
        </v-card-text>
      </v-card>

      <v-col cols="12" class="mt-12 mb-16 d-flex justify-center">
        <v-btn
          color="primary"
          rounded
          x-large
          min-width="300"
          class="d-flex justify-space-between text-none"
          elevation="1"
          :disabled="actionLoading"
          @click="addToCart"
        >
          <template v-if="actionLoading">
            <span>{{ addToShoppingCart ? "Legger til" : "Sender forespørsel" }}...</span>
            <v-progress-circular indeterminate :size="20" color="primary"></v-progress-circular>
          </template>

          <template v-else>
            <span>{{ addToShoppingCart ? "Legg til i handlekurv" : "Send forespørsel" }}</span>
            <v-icon right> fal {{ addToShoppingCart ? "fa-cart-plus" : "fa-check" }} </v-icon>
          </template>
        </v-btn>
      </v-col>
    </template>
    <!-- DEBUG -->
    <!-- <v-container class="text-left primary darken-2 white--text pa-0 my-12">
      <h4 class="pa-5 primary darken-4">Summary.vue</h4>
      <v-container class="pa-5">
        <pre>addToCartSuccess: {{ addToCartSuccess }}</pre>
        <pre>sendRequestSuccess: {{ sendRequestSuccess }}</pre>
      </v-container>
    </v-container> -->
  </v-container>
</template>
