<script>
import NumberInput from "@/components/NumberInput.vue";

export default {
  name: "ProductTile",
  components: {
    NumberInput,
  },

  props: {
    amountSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
    product: {
      type: Object,
      required: true,
    },
    transparent: {
      type: Boolean,
      required: false,
    },
    rounded: {
      type: Boolean,
      required: false,
    },
    hideActions: {
      type: Boolean,
      required: false,
    },
    chosen: {
      type: Boolean,
      required: false,
    },
    userCanSelectQuantity: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialValue: {
      type: Number,
      required: false,
    },
  },

  data: function () {
    return {
      showPdf: false,
      imageModalOpen: false,
      infoModalOpen: false,
      quantity:
        this.initialValue ||
        (this.product.isUserCanChangeQuantity ? 0 : this.product.quantity > 0 ? this.product.quantity : 1),
    };
  },

  mounted() {},

  computed: {},

  watch: {},

  methods: {
    selectProduct: function () {
      this.$emit("productSelected", {
        id: this.product.id,
        name: this.product.text,
        productId: this.product.productId,
        quantity: this.quantity,
      });
    },
    setQuantity: function (data) {
      this.quantity = data.value;
      this.selectProduct();
    },
  },
};
</script>

<template>
  <v-card
    class="d-flex justify-space-between align-center flex-column text-center"
    :class="{
      'background lighten-1': !transparent,
      transparent: transparent,
      'rounded-xl': rounded,
    }"
    elevation="0"
    min-height="100%"
  >
    <v-container style="position: relative; flex-grow: 1" class="d-flex justify-center align-center">
      <v-container style="position: absolute; top: 0" class="d-flex justify-end align-center">
        <!-- Information Link -->
        <template v-if="!product.description2 && product.text2">
          <v-tooltip top open-delay="600">
            <template v-slot:activator="hoverScope">
              <v-hover v-slot="{ hover }">
                <div
                  v-on="hoverScope.on"
                  :style="{
                    opacity: hover ? '1' : '.3',
                    zIndex: '10',
                  }"
                >
                  <a :href="product.text2" target="_blank" class="text-decoration-none">
                    <v-icon
                      role="button"
                      class="pa-0"
                      color="error"
                      v-bind="props"
                      :style="{
                        height: 'inherit',
                      }"
                      large
                    >
                      mdi-information-outline
                    </v-icon>
                  </a>
                </div>
              </v-hover>
            </template>
            <span>Vis utfyllende informasjon om produktet</span>
          </v-tooltip>
        </template>

        <!-- Information Modal -->
        <v-dialog v-if="product.description2" v-model="infoModalOpen" width="auto" :retain-focus="false">
          <template v-slot:activator="{ props }">
            <v-tooltip top open-delay="600">
              <template v-slot:activator="hoverScope">
                <v-hover v-slot="{ hover }">
                  <div
                    v-on="hoverScope.on"
                    :style="{
                      opacity: hover ? '1' : '.3',
                      zIndex: '10',
                    }"
                  >
                    <v-icon
                      role="button"
                      class="pa-0"
                      color="error"
                      v-bind="props"
                      @click="infoModalOpen = true"
                      :style="{
                        height: 'inherit',
                      }"
                      large
                    >
                      mdi-information-outline
                    </v-icon>
                  </div>
                </v-hover>
              </template>
              <span>Vis utfyllende informasjon om produktet</span>
            </v-tooltip>
          </template>

          <v-card>
            <v-card-text class="pa-8" style="max-width: 1000px">
              <span v-html="product.description2"></span>
              <a :href="product.text2" target="_blank">Les mer om produktet</a>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" block @click="infoModalOpen = false">Lukk informasjon</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Image Modal -->
        <v-dialog
          v-if="product.imgSrc.replace('/Images/', '') != ''"
          v-model="imageModalOpen"
          width="auto"
          :retain-focus="false"
        >
          <template v-slot:activator="{ props }">
            <v-tooltip top open-delay="600">
              <template v-slot:activator="hoverScope">
                <v-hover v-slot="{ hover }">
                  <div
                    v-on="hoverScope.on"
                    :style="{
                      opacity: hover ? '1' : '.3',
                      zIndex: '10',
                    }"
                  >
                    <v-icon
                      role="button"
                      class="pa-0"
                      color="error"
                      v-bind="props"
                      @click="imageModalOpen = true"
                      :style="{
                        height: 'inherit',
                      }"
                      large
                    >
                      mdi-magnify-plus-outline
                    </v-icon>
                  </div>
                </v-hover>
              </template>
              <span>Vis en større versjon av bildet</span>
            </v-tooltip>
          </template>

          <v-card>
            <v-card-text>
              <v-img
                v-if="product.imgSrc.includes('http')"
                contain
                max-height="60vh"
                class="my-3 rounded"
                :src="product.imgSrc"
              >
                <template v-slot:placeholder>
                  <v-skeleton-loader tile type="image" />
                </template>
              </v-img>
              <v-img
                v-else
                contain
                max-height="60vh"
                class="my-3 rounded"
                :src="'https://www.canes.no' + product.imgSrc"
              >
                <template v-slot:placeholder>
                  <v-skeleton-loader tile type="image" />
                </template>
              </v-img>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" block @click="imageModalOpen = false">Lukk bilde</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>

      <v-img
        v-if="product.imgSrc.includes('http')"
        contain
        min-width="200"
        max-width="200"
        min-height="200"
        max-height="200"
        :src="product.imgSrc"
      >
        <template v-slot:placeholder>
          <v-skeleton-loader tile type="image" />
        </template>
      </v-img>

      <v-img
        v-else-if="product.imgSrc.replace('/Images/', '') != ''"
        contain
        min-width="200"
        max-width="200"
        min-height="200"
        max-height="200"
        :src="'https://www.canes.no' + product.imgSrc"
      >
        <template v-slot:placeholder>
          <v-skeleton-loader tile type="image" />
        </template>
      </v-img>

      <v-container
        v-else
        style="height: 200px; width: 200px; background-color: var(--v-background-darken1)"
        class="d-flex justify-center"
      >
        <v-icon x-large> mdi-image-off-outline </v-icon>
      </v-container>
    </v-container>

    <v-card-title class="text-subtitle-2">
      {{ product.text }}
    </v-card-title>

    <v-card-actions class="justify-center" v-if="!hideActions">
      <NumberInput
        v-if="product.isUserCanChangeQuantity"
        rounded
        :colored="quantity > 0"
        :value="quantity"
        legend=""
        @amountChanged="setQuantity"
      />
      <!-- Single -->
      <v-btn
        v-else
        color="primary"
        :outlined="!chosen"
        rounded
        class="text-none my-auto"
        elevation="1"
        @click="selectProduct"
      >
        {{ chosen ? "Valgt" : "Velg" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
