<script>
export default {
  name: "StepSelectionMultiple",
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    selectedSteps: {
      type: Array,
      required: true,
    },
  },
  data: function() {
    return {
        stepsChosen: this.selectedSteps
    };
  },
  mounted() {
  },
  methods: {
    updateStepChoiceData: function(stepId) {
      this.$emit("stepSelected", stepId);
      this.$store.commit("toggleStep", stepId);
      this.$emit("stepValidityChanged", this.stepsChosen.length > 1);
    },
  },
  computed: {
    steps: function() {
        if(this.stepData.steps?.length > 0) {
            return this.stepData.steps;
        }
        else if(this.stepData.productBuilderHiddenSteps?.length > 0) {
            return this.stepData.productBuilderHiddenSteps;
        }
        else {
            return [];
        }
    }
  }
};
</script>

<style></style>

<template>
  <v-container>
    <v-row class="text-center my-16">
      <v-col cols="12">
        <span
          v-if="stepData.description2"
          v-html="stepData.description2"
          class="my-8"
        ></span>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        v-for="step in steps"
        :key="step.id"
        cols="12"
        sm="6"
        lg="3"
      >
        <v-card
          elevation="0"
          rounded="xl"
          class="background lighten-1 text-center pt-10 pb-16 outer"
          min-height="100%"
          style="position: relative"
        >
        
        <template v-if="step.hasLink">
            <v-tooltip top open-delay="600">
              <template v-slot:activator="hoverScope">
                <v-hover v-slot="{ hover }">
                  <v-icon
                    role="button"
                    v-on="hoverScope.on"
                    class="pa-0"
                    color="error"
                    :style="{ position: 'absolute', top: '.5rem', right: '.5rem', opacity: hover ? '1' : '.3' }"
                    large
                  >
                    mdi-information
                  </v-icon>
                </v-hover>
              </template>
              <span>Les mer om produktet</span>
            </v-tooltip>
          </template>

          <p>
            <strong>{{ step.header }}</strong>
          </p>
          <!-- <span
            v-if="step.description"
            v-html="step.description"
            class="my-8"
          ></span> -->

          <v-card-actions class="actions justify-center">
            <div class="canes-checkbox">
              <input
                type="checkbox"
                :id="step.id"
                :value="step.id"
                v-on:change="$emit('step.id', stepId)"
                v-model="stepsChosen"
              />
              <label :for="step.id" class="mt-2"></label>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
