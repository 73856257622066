import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import Guide from "../views/Guide.vue";
import StepHandler from "../views/StepHandler.vue";
// import Steps from "../views/Steps.vue";
// import StepsV2 from "../views/StepsV2.vue";
import Summary from "../views/Summary.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/guide",
    name: "Guide",
    component: StepHandler,
  },
  {
    path: "/guide/:step",
    name: "Steps",
    component: StepHandler,
  },
  {
    path: "/summary",
    name: "Summary",
    component: Summary,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
