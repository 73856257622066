<script>
export default {
  name: "Hero",
  props: {
    title: String,
    subtitle: String,
  },
  data: () => ({}),
};
</script>

<style>
  .hero-content img {
    width: 100%;
    object-fit: contain;
  }
</style>

<template>
  <v-container>
    <v-row class="text-center" justify="center">
      <v-col sm="12" lg="6" xl="4" class="hero-content">
        <slot name="title">
          <h1 v-if="title">
            <b>{{ title }}</b>
          </h1>
        </slot>

        <slot name="subtitle">
          <div v-if="subtitle" class="mt-6" v-html="subtitle"></div>
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>