<script>
export default {
  name: "StepSelectionSingle",
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    selectedSteps: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      imageModalOpen: [],
    };
  },
  mounted() {
    for (let i = 0; i < this.steps.length; i++) {
      this.imageModalOpen.push(false);
    }
  },
  methods: {
    updateStepChoiceData: function (step) {
      const stepIsAlreadySelected = this.selectedSteps.some((s) => s.id === step.id);

      this.selectedSteps.forEach(stepToDeselect => {
        this.$emit("stepSelected", stepToDeselect);
      })

      if (!stepIsAlreadySelected) {
        this.$emit("stepSelected", step);
      }

      this.$emit("stepValidityChanged", this.selectedSteps.length === 1);
    },
    openImageModal: function (id) {
      this.imageModalOpen[id] = true;
    },
  },
  computed: {
    steps: function () {
      if (this.stepData.steps?.length > 0) {
        return this.stepData.steps;
      } else if (this.stepData.productBuilderHiddenSteps?.length > 0) {
        return this.stepData.productBuilderHiddenSteps;
      } else {
        return [];
      }
    },
  },
};
</script>

<style></style>

<template>
  <v-container>
    <v-row class="text-center my-16">
      <v-col cols="12">
        <span v-if="stepData.description2" v-html="stepData.description2" class="my-8"></span>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col v-for="(step, stepIndex) in steps" :key="step.id" cols="12" sm="6" md="4" lg="3" xl="2">
        <v-card
          elevation="1"
          rounded="xl"
          class="background lighten-1 rounded-xl d-flex justify-space-between align-center flex-column text-center pt-2 pb-6 px-2"
          min-height="100%"
          style="position: relative"
        >
          <template v-if="step.hasLink">
            <v-tooltip top open-delay="600">
              <template v-slot:activator="hoverScope">
                <v-hover v-slot="{ hover }">
                  <v-icon
                    role="button"
                    v-on="hoverScope.on"
                    class="pa-0"
                    color="error"
                    :style="{ position: 'absolute', top: '.5rem', right: '.5rem', opacity: hover ? '1' : '.3' }"
                    large
                  >
                    mdi-information
                  </v-icon>
                </v-hover>
              </template>
              <span>Les mer om produktet</span>
            </v-tooltip>
          </template>

          <v-dialog
            v-if="step.slider.length > 0"
            v-model="imageModalOpen[stepIndex]"
            width="auto"
            :retain-focus="false"
          >
            <template v-slot:activator="{ props }">
              <v-tooltip top open-delay="600">
                <template v-slot:activator="hoverScope">
                  <v-hover v-slot="{ hover }">
                    <div
                      v-on="hoverScope.on"
                      :style="{
                        position: 'absolute',
                        top: '.5rem',
                        right: '.5rem',
                        opacity: hover ? '1' : '.3',
                        zIndex: '10',
                      }"
                    >
                      <v-icon
                        role="button"
                        class="pa-0"
                        color="error"
                        v-bind="props"
                        @click="$set(imageModalOpen, stepIndex, true)"
                        :style="{
                          height: 'inherit',
                        }"
                        large
                      >
                        mdi-magnify-plus-outline
                      </v-icon>
                    </div>
                  </v-hover>
                </template>
                <span>Vis en større versjon av bildet</span>
              </v-tooltip>
            </template>

            <v-card>
              <v-card-text>
                <v-img
                  v-if="step.slider.length > 0"
                  contain
                  max-height="60vh"
                  class="my-3 rounded"
                  :src="step.slider[0].imgSrc"
                  :alt="step.slider[0].label"
                >
                  <template v-slot:placeholder>
                    <v-skeleton-loader tile type="image" />
                  </template>
                </v-img>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" block @click="$set(imageModalOpen, stepIndex, false)">Lukk bilde</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-img
            v-if="step.slider.length > 0"
            contain
            max-height="150"
            max-width="150"
            class="my-3 rounded"
            :src="step.slider[0].imgSrc"
            :alt="step.slider[0].label"
          >
            <template v-slot:placeholder>
              <v-skeleton-loader tile type="image" />
            </template>
          </v-img>

          <v-row v-else style="min-width: 150px; min-height: 150px" class="d-flex justify-center">
            <v-icon x-large> mdi-image-off-outline </v-icon>
          </v-row>

          <v-card-title class="text-subtitle-2">
            {{ step.header }}
          </v-card-title>

          <v-card-actions class="actions justify-center">
            <v-btn
              color="primary"
              :outlined="!selectedSteps.some((s) => s.id === step.id)"
              rounded
              class="text-none my-auto"
              elevation="1"
              @click="updateStepChoiceData(step)"
            >
              {{ selectedSteps.some((s) => s.id === step.id) ? "Valgt" : "Velg" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- DEBUG -->
    <!-- <v-container class="text-left primary darken-2 white--text pa-0 my-12">
      <h4 class="pa-5 primary darken-4">StepSelectionSingle.vue</h4>
      <pre class="pa-5">{{ selectedSteps.map((s) => s.id) }}</pre>
    </v-container> -->
  </v-container>
</template>
