import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { store } from "./store/store";

Vue.config.productionTip = false;

Vue.filter('prettyNumbers', function (value) {
  var parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
})

new Vue({
  store,
  router,
  vuetify,
  beforeCreate() {
    this.$store.commit("initializeStore");
  },
  render: (h) => h(App),
}).$mount("#app");
