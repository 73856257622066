export default {
  methods: {
    getFriggAttributeValue: function(product, content) {
      let value = [];

      if(product.attributes) {
        value = product.attributes.filter(obj => {
          return obj?.key === content
        })

        if(value.length > 0) {
          return value[0].content
        }
      }
      else if(product.Attributes) {
        value = product.attributes.filter(obj => {
          return obj.TextID === content
        })

        if(value.length > 0) {
          return value[0].Content
        }
      }

      return undefined
    },

    productHasAnyQuantity: function(product) {
      return (
        product.amount > 0
        || product.breakage.length > 0
        || product.sleeves?.amount > 0
        || product.jackets?.amount > 0
        || product.hoods?.amount > 0
      )
    }
  }
};