<script>
export default {
  name: "DeleteStepIcon",
  components: {},
  props: {
    composition: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      showCompositionDeletionDialog: false,
    };
  },
  beforeMount() {},
  mounted() {},
  methods: {
    removeComposition: function (id) {
      this.$emit("removeComposition", id);
      this.showCompositionDeletionDialog = false;
    },
  },
  computed: {},
  watch: {},
};
</script>

<style>
</style>

<template>
  <v-dialog
    :key="'dialog_' + composition.id"
    :retain-focus="false"
    v-model="showCompositionDeletionDialog"
    max-width="600"
  >
    <template v-slot:activator="dialogScope">
      <v-tooltip top open-delay="600">
        <template v-slot:activator="hoverScope">
          <v-hover v-slot="{ hover }">
            <v-icon
              :key="`icon_${composition.id}`"
              role="button"
              v-on="{ ...dialogScope.on, ...hoverScope.on }"
              class="pa-0"
              :color="hover ? 'error' : null"
            >
              mdi-close-circle
            </v-icon>
          </v-hover>
        </template>
        <span>Slett komposisjon</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>Er du sikker på at du vil slette?</v-card-title>
      <v-card-text
        >Dette vil slette hele komposisjonen <em>{{ composition.name }}</em
        >.</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-icon x-large role="button" @click="removeComposition(composition.id)" class="pa-0" color="green darken-1">
          mdi-check-circle
        </v-icon>
        <v-icon x-large role="button" @click="showCompositionDeletionDialog = false" class="pa-0" color="red darken-1">
          mdi-close-circle
        </v-icon>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
