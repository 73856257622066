<script>
import StepSelectionSingle from "@/components/templates/StepSelectionSingle.vue";
import StepSelectionMultiple from "@/components/templates/StepSelectionMultiple.vue";
import StepContainer from "@/components/templates/StepContainer.vue";
import StepComposition from "@/components/templates/StepComposition/StepComposition.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "StepHandler",
  components: {
    StepSelectionSingle,
    StepSelectionMultiple,
    StepContainer,
    StepComposition,
  },
  data: function () {
    return {
      stepChoices: {
        name: null,
        id: null,
        template: null,
        products: [],
        compositionCollection: [],
        steps: [],
        additionalInfo: {},
        seenBefore: false,
        stepValid: false,
      },
      loading: true,
      tempProjectName: null,
      projectNameChosen: false,
      projectNameValid: true,
      projectNameRules: [
        (value) => !!value || "Påkrevd.",
        (value) => (value && value.length >= 3) || "Minimum 3 karakterer.",
      ],
    };
  },
  async mounted() {
    // Load all step data
    await this.loadStepData();

    // If there is a project name
    if (this.projectName) {
      // Set component variables
      this.tempProjectName = this.projectName;
      this.projectNameChosen = true;
    }

    // If we are on the first step
    if (this.stepIndex === 0) {
      // If no choices has been made
      if (this.$store.getters.choices.length === 1 && this.stepData.steps.length > 0) {
        // Add all choices from step
        this.stepData.steps.forEach((step) => {
          this.toggleStep(step);
        });
      }

      this.$router.push("/guide/1");
    }
  },
  methods: {
    /**
     * Method to load step data from the store
     */
    loadStepData: function () {
      this.loading = true;

      // If the current index exists in our stored choices
      if (this.stepIndex >= 0 && this.stepIndex < this.$store.state.choices.length) {
        // Update component data with previously chosen data
        this.stepChoices = this.$store.state.choices[this.stepIndex];
      }

      // If the step data has been fetched
      if (Object.keys(this.stepData).length > 0) {
        // Store some step specific values
        this.stepChoices.name = this.stepChoices.name ?? (this.stepData.name || this.stepData.header);
        this.stepChoices.id = this.stepChoices.id ?? this.stepData.id;
        this.stepChoices.template = this.stepChoices.template ?? (this.stepData.templateName || "start");

        // Store changes
        this.storeStepData();
      }

      // Deactivate loading
      this.loading = false;
    },

    /**
     * Method to store current step data to the store
     */
    storeStepData: function () {
      this.$store.commit("updateStepData", {
        stepIndex: this.stepIndex,
        stepChoices: this.stepChoices,
      });
    },

    /**
     * Method to toggle a step
     *
     * @param {Object} step Step to be toggled
     */
    toggleStep: function (step) {
      const stepIndex = this.stepChoices.steps.indexOf(step);
      const exists = stepIndex != -1;

      if (exists) {
        this.stepChoices.steps.splice(stepIndex);
        this.$store.commit("removeStepChoices", step);
      } else {
        this.stepChoices.steps.splice(this.stepIndex, 0, step);
        this.$store.commit("addStep", step.id);
      }
    },

    /**
     * Method to toggle a step
     *
     * @param {Object} step Step to be toggled
     */
    toggleStepAtIndex: function (step) {
      const stepIndex = this.stepChoices.steps.indexOf(step);
      const exists = stepIndex != -1;

      if (exists) {
        const stepsToRemove = this.recursivelyGetAllAssociatedSteps(step);
        this.stepChoices.steps = this.stepChoices.steps.filter((s) => !stepsToRemove.includes(s.id));
        this.$store.commit("removeSteps", stepsToRemove);
      } else {
        this.stepChoices.steps.splice(this.stepIndex, 0, step);
        this.$store.commit("addStepAtIndex", { step: step, index: this.stepIndex + 1 });
      }
    },

    /**
     * Method to add a product to the current step choices if it doesn't already exist
     *
     * @param {Object} product The product to be added
     */
    addProductUpdateIfExists: function (product) {
      // If the choice doesn't already contain products
      if (this.stepChoices.products?.length === 0) {
        // Add product to choice
        this.stepChoices.products.push(product);
      }
      // If the choice already contains products
      else {
        // Try to find the product index
        let productIndex = this.stepChoices.products.findIndex(
          (p) => p.id === product.id && p.parentStep === product.parentStep
        );

        // If the product index is found
        if (productIndex != -1) {
          // Update the product
          this.stepChoices.products[productIndex] = product;
        }
        // If the product index is not found
        else {
          // Push the product into the choices
          this.stepChoices.products.push(product);
        }
      }
    },

    /**
     * Method to remove product from choices
     *
     * @param {Array} product
     */
    removeProductsIfExists: function (products) {
      const items = this.recursivelyGetAllItems(this.stepData);
      let productsToRemove = products;

      products.forEach((product) => {
        const itemsAffected = items.filter((p) => p.filterItemIds.includes(product.id));
        productsToRemove = [...productsToRemove, ...itemsAffected];
      });

      productsToRemove.forEach((product) => {
        const parentStepId = product.parentStep || product.productBuilderStepId;
        const productIndex = this.stepChoices.products.findIndex(
          (p) => p.id === product.id && p.parentStep === parentStepId
        );

        if (productIndex != -1) {
          this.stepChoices.products.splice(productIndex, 1);
          this.storeStepData();
        }
      });
    },

    /**
     * Method to update the step validity
     *
     * @param {Boolean} validity
     */
    setValidity(validity) {
      this.stepChoices.stepValid = validity;
      this.stepChoices = { ...this.stepChoices };
    },

    /**
     *
     * @param {Object} info
     */
    setAdditionalInfo(info) {
      this.stepChoices.additionalInfo = info;
    },

    /**
     *
     */
    setProjectName() {
      this.projectNameChosen = true;
      this.$store.commit("setProjectName", this.tempProjectName);
    },

    /**
     *
     * @param {Object} composition
     */
    addCompositionUpdateIfExists(composition) {
      this.$store.commit("addCompositionUpdateIfExists", composition);
      this.$store.dispatch("addToChoiceCache", { cacheId: composition.id, choiceId: this.stepData.id });
    },

    restoreCompositionCache(cacheId) {
      if (this.choiceCache[cacheId]) {
        this.stepChoices.products = [...this.choiceCache[cacheId].products];
      } else {
        console.warn("No cache found for id: " + cacheId);
      }
    },

    /**
     *
     */
    resetCompositionInput() {
      this.stepChoices.additionalInfo = {};
    },

    /**
     *
     * @param {Object} step
     * @returns
     */
    recursivelyGetAllAssociatedSteps(step) {
      let res = [step.id];

      if (step?.steps) {
        step.steps.map((s) => {
          res.push(s.id);
          res = [...res, ...this.recursivelyGetAllAssociatedSteps(s)];
        });
      } else if (step?.productBuilderHiddenSteps) {
        step.productBuilderHiddenSteps.map((s) => {
          res.push(s.id);
          res = [...res, ...this.recursivelyGetAllAssociatedSteps(s)];
        });
      }

      return res;
    },

    /**
     *
     * @param {Object} step
     */
    recursivelyGetAllItems(step) {
      let res = [];

      if (step.items) {
        res = [...step.items];
      }

      if (step.productBuilderHiddenSteps) {
        step.productBuilderHiddenSteps.map((s) => {
          res = [...res, ...this.recursivelyGetAllItems(s)];
        });
      }

      return res;
    },
  },
  computed: {
    /**
     * Method to determine if the app is loading data
     */
    isLoading: function () {
      return this.loading || Object.keys(this.friggProductDetails) < 1 || Object.keys(this.stepData) < 1;
    },

    /**
     * @return {Number} Index of the stored choices related to the current step
     */
    stepIndex: function () {
      return parseInt(this.$route.params.step) || 0;
    },

    /**
     * @return {Object} Step data
     */
    stepData: function () {
      // If there is no step parameter telling us which step we are at
      if (this.stepIndex === 0) {
        // Return the builder itself
        return this.$store.getters.builder;
      }
      // If we are at a specific step
      else {
        // Get the index of the step data
        const stepIndex = this.$store.state.allSteps.findIndex((step) => step.id == this.stepChoices.id);

        // If there is data, return it
        if (stepIndex != -1 && this.$store.state.allSteps[stepIndex]) {
          return this.$store.state.allSteps[stepIndex];
        }
      }

      return {};
    },

    /**
     * @return {Boolean} True or false depending on the current step criteria being met
     */
    stepValid: function () {
      switch (this.stepChoices.template) {
        case "start":
        case "step-selection-multiple":
          if (this.stepChoices.steps?.length > 0) {
            return true;
          }
          break;

        case "step-selection-single":
          if (this.stepChoices.steps?.length === 1) {
            return true;
          }
          break;
      }

      return false;
    },

    /**
     * @return {String} Name of the current step template
     */
    template: function () {
      return this.stepData.templateName || "start";
    },

    /**
     * @return {String} The URL to the next step in the process
     */
    nextUrl: function () {
      return this.stepIndex + 1 >= this.$store.state.choices.length ? "/summary" : `/guide/${this.stepIndex + 1}`;
    },

    stepCompleteText: function () {
      return this.stepIndex < this.$store.getters.choices.length - 1 ? "Neste" : "Til oppsummering";
    },

    ...mapState(["friggProductDetails"]),
    ...mapGetters(["projectName", "compositions", "choiceCache"]),
  },
  watch: {
    stepChoices: {
      deep: true,
      handler() {
        this.storeStepData();
      },
    },
    $route() {},
  },
};
</script>

<style>
.canes-checkbox {
  position: relative;
  min-width: 28px;
}

.canes-checkbox label {
  background-color: inherit;
  border: 1px solid var(--v-primary-base);
  border-radius: 30%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.canes-checkbox.not-selected label {
  border: 1px solid var(--v-secondary-base);
}

.canes-checkbox:not(.not-selected) label:after {
  border: 2px solid var(--v-primary-base);
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.canes-checkbox.not-selected label:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "\00d7";
  font-size: 24px;
  color: var(--v-secondary-base);
  line-height: 25px;
  text-align: center;
}

.canes-checkbox input[type="checkbox"] {
  visibility: hidden;
}

.canes-checkbox input[type="checkbox"]:checked + label {
  background-color: inherit;
  border-color: var(--v-primary-base);
}

.canes-checkbox.not-selected input[type="checkbox"] + label {
  background-color: inherit;
  border-color: var(--v-secondary-base);
}

.canes-checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.canes-checkbox.not-selected input[type="checkbox"] + label:after {
  opacity: 1;
}

.canes-border {
  border: 2px solid var(--v-primary-base);
}
</style>

<template>
  <!-- Loading -->
  <v-container v-if="isLoading" fill-height class="text-center">
    <v-row class="fill-height d-flex flex-column align-center" align-content="center" justify="center">
      <v-progress-circular indeterminate :size="70" color="primary"></v-progress-circular>
      <span class="mt-5">Laster data</span>
    </v-row>
  </v-container>

  <!-- Project name -->
  <v-container v-else-if="!projectNameChosen">
    <v-row class="mt-16 d-flex flex-column align-center">
      <v-col cols="12" class="text-center">
        <h2>Vennligst velg et prosjektnavn</h2>
      </v-col>
      <v-col cols="12" sm="8" md="6" xl="4" class="mt-12">
        <v-form ref="projectNameForm" v-model="projectNameValid" @submit="setProjectName">
          <v-text-field
            label="Ditt prosjektnavn"
            :rules="projectNameRules"
            ref="projectNameInput"
            hide-details="auto"
            v-model="tempProjectName"
          ></v-text-field
        ></v-form>
      </v-col>
      <v-col cols="12" sm="6" md="4" xl="4" class="mt-12 d-flex justify-center">
        <v-btn
          color="primary"
          rounded
          x-large
          min-width="300"
          class="d-flex justify-space-between text-none mb-16"
          elevation="1"
          :disabled="!projectNameValid"
          @click="setProjectName"
        >
          Lagre
          <v-icon right> fal fa-arrow-right </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>

  <!-- Steps -->
  <v-container v-else>
    <!-- Step Selection Single Step -->
    <template v-if="template === 'step-selection-single'">
      <step-selection-single
        :stepData="stepData"
        :selectedSteps="stepChoices.steps"
        @stepValidityChanged="(validity) => setValidity(validity)"
        @stepSelected="(step) => toggleStepAtIndex(step)"
      />
    </template>

    <!-- Step Selection Multiple Step -->
    <template v-if="template === 'step-selection-multiple'">
      <step-selection-multiple
        :stepData="stepData"
        :selectedSteps="stepChoices.steps"
        @stepValidityChanged="(validity) => setValidity(validity)"
        @stepSelected="(step) => toggleStep(step)"
      />
    </template>

    <!-- Step Composition Step -->
    <template v-if="template === 'step-composition'">
      <step-composition
        :stepData="stepData"
        :selectedProducts="stepChoices.products"
        :compositionCollection="compositions"
        :additionalInfo="stepChoices.additionalInfo"
        @stepValidityChanged="(validity) => setValidity(validity)"
        @stepSelected="(step) => toggleStep(step)"
        @addProductUpdateIfExists="(product) => addProductUpdateIfExists(product)"
        @removeProduct="(product) => removeProductsIfExists([product])"
        @setAdditionalInfo="(info) => setAdditionalInfo(info)"
        @addCompositionUpdateIfExists="(composition) => addCompositionUpdateIfExists(composition)"
        @restoreCompositionCache="(cacheId) => restoreCompositionCache(cacheId)"
        @removeComposition="(id) => $store.commit('removeComposition', id)"
        @resetCompositionInput="resetCompositionInput"
      />
    </template>

    <!-- Step Container Step -->
    <template v-if="template === 'step-container'">
      <step-container
        :stepData="stepData"
        :selectedProducts="stepChoices.products"
        @stepValidityChanged="(validity) => setValidity(validity)"
        @stepSelected="(step) => toggleStep(step)"
        @addProductUpdateIfExists="(product) => addProductUpdateIfExists(product)"
        @removeProduct="(product) => removeProductsIfExists([product])"
        @setAdditionalInfo="(info) => setAdditionalInfo(info)"
        @addCompositionUpdateIfExists="(composition) => $store.commit('addCompositionUpdateIfExists', composition)"
        @removeComposition="(id) => $store.commit('removeComposition', id)"
        @resetCompositionInput="resetCompositionInput"
      />
    </template>

    <v-row justify="center" class="mb-16 mt-5 pb-16" style="gap: 2rem">
      <v-btn
        :to="nextUrl"
        color="primary"
        rounded
        x-large
        min-width="300"
        class="d-flex justify-space-between text-none"
        elevation="1"
        :disabled="!stepChoices.stepValid"
      >
        {{ stepCompleteText }}
        <v-icon right> fal fa-arrow-right </v-icon>
      </v-btn>
    </v-row>

    <!-- DEBUG -->
    <!-- <v-container class="text-left primary darken-2 white--text pa-0 my-12">
      <h4 class="pa-5 primary darken-4">StepHandler.vue</h4>
      <pre class="pa-5">{{ stepChoices }}</pre>
    </v-container> -->
  </v-container>
</template>
