<script>
import ContainerStep from "@/components/ContainerStep.vue";
// import NumberInput from "@/components/NumberInput.vue";

export default {
  name: "StepContainer",
  components: {
    ContainerStep,
    // NumberInput,
  },
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    selectedProducts: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      currentStep: 0,
      products: this.selectedProducts,
      toggleUserEditName: false,
      stepValidity: [],
      stepContainerValid: false,
      reloadKey: 0,
      openContainerId: null,
    };
  },
  beforeMount() {
    this.stepValidity = Array.from(Array(this.steps.length), () => false);
  },
  mounted() {
    // Add all attached products directly to the choices
    this.stepData.items.forEach((product) => {
      this.$emit("addProduct", {
        id: product.id,
        productId: product.productId,
        quantity: 1,
        parentStep: this.stepData.id,
      });
    });

    // this.stepData.productBuilderHiddenSteps.forEach((step) => {
    //   this.$emit("stepSelected", step.id);
    // });
  },
  methods: {
    /**
     * Method to update the product selection of a parent step
     *
     * @param {*} parentStep The ID of the step
     * @param {*} products The current products array
     */
    addProductsUpdateIfExists: function (parentStep, products) {
      // If there are any products provided
      if (products.length > 0) {
        // Iterate every product
        products.forEach((product) => {
          // Find the array index of current product
          let index = this.products.findIndex((p) => p.id === product.id && p.parentStep === product.parentStep);

          // If the product is found
          if (index != -1) {
            // Update the product data
            this.products[index] = product;
          }
          // If the product isn't found
          else {
            // Add it to the products collection
            this.products.push(product);
          }

          // Tell the parent component that the product selection is updated
          this.$emit("addProductUpdateIfExists", product);
        });
      }
      // If there are no products provided, which means we have to remove products
      else {
        console.error("addProductsUpdateIfExists was called in StepContainer.vue without any products");
      }
    },

    /**
     *
     * @param {*} product
     */
    addProductUpdateIfExists: function (product) {
      this.products = this.products.push(product);
      this.$emit("addProductUpdateIfExists", product);
    },

    /**
     *
     * @param {Array} product
     */
    removeProducts: function (products = []) {
      if (products.length < 1) {
        const productsToRemove = [...this.products];

        productsToRemove.forEach((product) => {
          this.$emit("removeProduct", product);
        });

        this.products = [];
      } else {
        products.forEach((product) => {
          this.products = this.products.filter((p) => p.id != product.id);
          this.$emit("removeProduct", product);
        });
      }
    },

    /**
     *
     * @param {*} stepIndex
     * @param {*} validity
     */
    updateStepValidity: function (stepIndex, validity) {
      this.$set(this.stepValidity, stepIndex, validity);
    },
  },
  computed: {
    steps: function () {
      if (this.stepData.steps?.length > 0) {
        return this.stepData.steps;
      } else if (this.stepData.productBuilderHiddenSteps?.length > 0) {
        return this.stepData.productBuilderHiddenSteps;
      } else {
        return [];
      }
    },
  },
  watch: {
    stepValidity: {
      handler(val) {
        const stepsCurrentlyActive = this.steps.map((step) => {
          const productsWithFilter = step.items.filter((product) => product.filterItemIds.length > 0);

          if (productsWithFilter.length > 0) {
            for (let i = 0; i < productsWithFilter.length; i++) {
              // Iterate all filters on product
              for (let j = 0; j < productsWithFilter[i].filterItemIds.length; j++) {
                // If we can find a product with this id
                if (this.$store.getters.productExistInChoices(productsWithFilter[i].filterItemIds[j])) {
                  return step.id;
                }
              }
            }
          } else {
            return step.id;
          }
        });

        if (val.filter((v, i) => stepsCurrentlyActive[i] != undefined && v === false).length > 0) {
          this.$emit("stepValidityChanged", false);
          this.stepContainerValid = false;
        } else {
          this.$emit("stepValidityChanged", true);
          this.stepContainerValid = true;
        }
      },
    },
  },
};
</script>

<style>
.canes-border {
  border: 2px solid var(--v-primary-base);
}
</style>

<template>
  <v-container :key="reloadKey">
    <!-- Main Card -->
    <v-card class="my-5" style="position: relative">
      <!-- Title -->
      <v-card-title> {{ stepData.header }}</v-card-title>

      <!-- Subtitle -->
      <v-card-text class="">
        <span v-html="this.stepData.description2"></span>
      </v-card-text>

      <v-divider></v-divider>

      <!-- Steps -->
      <container-step
        v-for="(step, index) in steps"
        :key="'container_step_' + index"
        :step="step"
        :selected-products="products.filter((p) => p.parentStep === step.id)"
        :display-divider="index != steps.length - 1"
        :open-override="step.id === openContainerId"
        @toggleOpenContainerId="(id) => (openContainerId = id)"
        @addProductsUpdateIfExists="({ parentStep, products }) => addProductsUpdateIfExists(parentStep, products)"
        @removeProduct="(product) => removeProducts([product])"
        @updateStepValidity="(validity) => updateStepValidity(index, validity)"
      >
      </container-step>
    </v-card>
  </v-container>
</template>
