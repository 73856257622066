<script>
import NumberInput from "@/components/NumberInput.vue";
import DeleteStepIcon from "./DeleteStepIcon.vue";

export default {
  name: "CompositionCollection",
  components: {
    NumberInput,
    DeleteStepIcon,
  },
  props: {
    compositionCollection: {
      type: Array,
      required: false,
    },
    parentId: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {};
  },
  mounted() {},
  methods: {
    /**
     *
     * @param {*} id
     */
    removeComposition: function (id) {
      this.$emit("removeComposition", id);
      this.$store.dispatch("deleteComposition", id);
    },

    setComposition: function (id) {
      this.$emit("setComposition", id);
    },

    calculateTotalQuantity: function (products) {
      return products.map((p) => p.quantity).reduce((total, quantity) => total + quantity, 0);
    },
  },
  computed: {},
  watch: {},
};
</script>

<style>
.canes-border {
  border: 2px solid var(--v-primary-base);
}

.center-icon > .v-alert__wrapper > .v-icon {
  margin: auto 1rem auto 0;
}
</style>

<template>
  <v-slide-y-transition>
    <v-container v-if="compositionCollection.length > 0" class="pa-0">
      <v-col cols="12" lg="10" xl="8" class="d-flex flex-column">
        <h2 class="font-weight-light">Komposisjonsoversikt</h2>
      </v-col>

      <v-row>
        <v-col
          v-for="(composition, index) in compositionCollection"
          :key="`composition_${index}`"
          cols="12"
          sm="6"
          md="6"
          lg="4"
          xl="3"
        >
          <v-slide-y-transition>
            <!-- Composition -->
            <v-card class="">
              <!-- Composition Title -->
              <v-card-title class="d-flex justify-space-between">
                <span>{{ composition.name }}</span>

                <div class="d-flex align-center" style="gap: 0.5rem">
                  <v-tooltip v-if="parentId === composition.parentId" top open-delay="600">
                    <template v-slot:activator="{ on }">
                      <v-hover v-slot="{ hover }">
                        <v-icon
                          role="button"
                          v-on="on"
                          :color="hover ? 'warning' : null"
                          class="pa-0"
                          @click="setComposition(composition.id)"
                        >
                          mdi-file-document-edit-outline
                        </v-icon>
                      </v-hover>
                    </template>
                    <span>Endre komposisjon</span>
                  </v-tooltip>

                  <DeleteStepIcon :composition="composition" @removeComposition="(id) => removeComposition(id)" />
                </div>
              </v-card-title>

              <!-- Composition Subtitle -->
              <v-card-subtitle
                >{{ calculateTotalQuantity(composition.products) }} produkter tilknyttet komposisjon</v-card-subtitle
              >

              <!-- Composition Content -->
              <div class="pt-3 pb-6">
                <v-row class="mx-5">
                  <v-col cols="3" class="pa-0">
                    <small><strong>Artikkelnr.</strong></small>
                  </v-col>
                  <v-col cols="2" class="pa-0">
                    <small><strong>Antall</strong></small>
                  </v-col>
                  <v-col cols="7" class="pa-0">
                    <small><strong>Produkt</strong></small>
                  </v-col>
                </v-row>
                <v-row v-for="product in composition.products" :key="product + '_' + Math.random()" class="mx-5">
                  <v-col cols="3" class="pa-0">
                    <small>{{ product.productId }}</small>
                  </v-col>
                  <v-col cols="2" class="pa-0">
                    <small>{{ product.quantity }}</small>
                  </v-col>
                  <v-col cols="7" class="pa-0" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                    <small :title="product.name">{{ product.name }}</small>
                  </v-col>
                </v-row>
              </div>

              <!-- Composition Quantity -->
              <v-card-actions class="pa-4">
                <NumberInput
                  :value="composition.quantity"
                  @amountChanged="({ value }) => (composition.quantity = value)"
                  :increment="1"
                  legend="Antall"
                />
              </v-card-actions> </v-card
          ></v-slide-y-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-slide-y-transition>
</template>
