<script>
export default {
  name: "StepChoice",
  components: {},
  props: {
    choiceData: {
      type: Object,
      required: true
    },
    chosen: {
      type: Boolean,
      required: false,
    }
  },
  data: function () {
    return {};
  },
  beforeMount() {},
  mounted() {},
  methods: {
    selectChoice: function (choice) {
      this.$emit("choiceSelected", {
        id: choice.id,
        answer: choice.header,
        type: "choice"
      });
    },
  },
  computed: {
  },
  watch: {},
};
</script>

<style>
</style>

<template>
    <v-card
      class="
        d-flex
        justify-space-between
        align-center
        flex-column
        text-center background lighten-1
      "
      elevation="0"
      min-height="100%"
    >
      <v-card-title class="text-subtitle-2">
        {{ choiceData.header }}
      </v-card-title>
      <v-card-actions class="justify-center">
        <v-btn
          @click="selectChoice(choiceData)"
          color="primary"
          :outlined="!chosen"
          rounded
          class="text-none my-auto"
          elevation="1"
        >
          {{ chosen ? "Valgt" : "Velg" }}
        </v-btn>
      </v-card-actions>
    </v-card>
</template>
