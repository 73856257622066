import "@fortawesome/fontawesome-pro/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa",
  },
  theme: {
    options: { customProperties: true },
    dark: localStorage.getItem("darktheme") == "true",
    themes: {
      light: {
        background: "#f1f1f1",
        primary: "#c22534",
        secondary: "#222",
        accent: "#8cc84f",
        error: "#b71c1c",
      },
      dark: {
        background: "#333",
        primary: "#008616",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
      },
    },
  },
});
