<script>
import { mapGetters } from "vuex";

export default {
  name: "App",

  created() {
    if (this.$route.query.id) {
      this.$store.commit("setCustomerid", this.$route.query.id);

      let query = Object.assign({}, this.$route.query);
      delete query.id;
      this.$router.replace({ query });
    }

    if (this.$route.query.builderid) {
      this.$store.commit("setBuilderid", this.$route.query.builderid);

      let query = Object.assign({}, this.$route.query);
      delete query.builderid;
      this.$router.replace({ query });
    }

    if (this.customerid) {
      this.$store.dispatch("getRemoteData");
    } else {
      this.alert = true;
    }
  },

  data() {
    return {
      god: localStorage.getItem("godmode") == "true",
      darktheme: localStorage.getItem("darktheme") == "true",
      builder: null,
      loading: true,
      errored: false,
      alert: false,
    };
  },

  methods: {},

  mounted() {},

  watch: {
    darktheme: function (value) {
      this.$vuetify.theme.dark = value;
      localStorage.setItem("darktheme", value);
    },
  },

  computed: {
    isLoading() {
      return !this.$store.getters.loadBuilderCompleted;
    },
    customerid() {
      return this.$store.getters.customerid;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    stepName() {
      let name = undefined;
      let previousName = undefined;
      let type = undefined;

      if (
        this.$route.name === "Steps" &&
        this.$route.params.step &&
        this.$route.params.step <= this.$store.state.choices.length
      ) {
        let steps = this.$store.state.builder.steps;
        let thisStep = this.$store.state.choices[this.$route.params.step - 1];
        let previousStep = this.$store.state.choices[this.$route.params.step - 2];

        for (let index in steps) {
          if (thisStep && steps[index].id == thisStep.id) {
            name = steps[index].header;
            type = steps[index].templateName;
          }

          if (previousStep && steps[index].id == previousStep.id) {
            previousName = steps[index].header;
            type = steps[index].templateName;
          }
        }

        if (type === "tilleggsvalg") {
          name = previousName;
        }
      }

      return name;
    },
    backUrl() {
      let step = parseInt(this.$route.params.step);

      if (this.$route.params.step) {
        if (this.$route.params.step > 1) return "/guide/" + (step - 1);
        else return "/guide";
      } else {
        return "/guide/" + (this.$store.getters.choices.length - 1);
      }
    },
    ...mapGetters(["projectName"]),
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.headerLink:hover .icon {
  transition: all 0.2s ease-in-out;
  color: var(--v-primary-base) !important;
}

.headerLink::before {
  display: none;
}
</style>

<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }" style="z-index: 1">
    <!-- Header -->
    <v-container>
      <v-row no-gutters>
        <!-- Left box in header -->
        <v-col cols="12" md="2" class="d-flex align-center justify-center">
          <template
            v-if="(this.$route.name === 'Steps' && this.$route.params.step > 1) || this.$route.name === 'Summary'"
          >
            <v-fade-transition appear>
              <v-btn class="headerLink" :ripple="false" text :to="backUrl">
                <v-icon class="mr-2 icon">fal fa-arrow-left</v-icon>
                <span>Tilbake</span>
              </v-btn>
            </v-fade-transition>
          </template>
        </v-col>

        <!-- Center box and logo in header -->
        <v-col cols="12" md="8" class="d-flex flex-column align-center justify-center">
          <router-link to="/">
            <v-img alt="Canes Logo" class="shrink mr-2" contain src="./assets/logo.png" height="150" />
          </router-link>
        </v-col>

        <!-- Right box in header -->
        <v-col cols="12" md="2" class="d-flex align-center justify-center">
          <v-fade-transition appear>
            <div class="d-flex align-center justify-end" v-if="this.$route.name != 'Home'">
              <!-- <v-expand-x-transition appear v-if="stepName">
                <h4 class="font-weight-medium" :key="stepName">
                  {{ stepName }}
                </h4>
              </v-expand-x-transition> -->
              <v-btn
                class="headerLink"
                :ripple="false"
                @click="
                  $store.dispatch('clearChoices');
                  $router.push('/');
                "
                text
              >
                <v-icon class="mr-2 icon">fal fa-times</v-icon>
                <span>Avslutt</span>
              </v-btn>
            </div>
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-container>

    <!-- Main content -->

    <v-alert v-if="alert" dense type="error" class="mx-10">
      App ble startet uten kunde-id, priser og ordre vil dermed ikke fungere.
    </v-alert>

    <v-container v-if="isLoading" fill-height class="text-center">
      <v-row class="fill-height d-flex flex-column align-center" align-content="center" justify="center">
        <v-progress-circular indeterminate :size="70" color="primary"></v-progress-circular>
        <span class="mt-5">Laster data</span>
      </v-row>
    </v-container>

    <v-main v-else>
      <v-fade-transition appear hide-on-leave>
        <router-view :key="$route.path" @reload="reload()"></router-view>
      </v-fade-transition>
    </v-main>

    <v-slide-y-reverse-transition>
      <div
        v-if="projectName"
        class="d-flex justify-space-around text-uppercase font-weight-bold grey--text text--lighten-2 px-5 pb-2"
        :class="{
          'text-h1': projectName.length < 20,
          'text-h6 text-sm-h5 text-lg-h2': projectName.length < 40,
          'text-h7 text-sm-h6 text-lg-h4': projectName.length >= 40,
        }"
        style="position: absolute; width: 100vw; z-index: -1; bottom: 0"
      >
        <span v-for="(char, index) in projectName" :key="`char_${index}_${char}`">{{ char }}</span>
      </div>
    </v-slide-y-reverse-transition>

    <v-container v-if="god" class="d-flex align-center">
      <v-switch
        class="mx-5"
        v-model="darktheme"
        hint="Because you're worth it!"
        inset
        label="Darkmode"
        persistent-hint
      ></v-switch>
      <v-btn
        class="mx-5"
        color="primary"
        @click="
          $store.dispatch('clearChoices');
          $router.push('/guide');
        "
      >
        Clear All Choices
      </v-btn>
    </v-container>
  </v-app>
</template>
