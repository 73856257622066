<script>
import ProductTile from "@/components/ProductTile.vue";
import ContainerStep from "@/components/ContainerStep.vue";
import CompositionCollection from "@/components/templates/StepComposition/CompositionCollection.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "StepComposition",
  components: {
    ProductTile,
    ContainerStep,
    CompositionCollection,
  },
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    selectedProducts: {
      type: Array,
      required: true,
    },
    compositionCollection: {
      type: Array,
      required: false,
    },
    additionalInfo: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      id: uuidv4(),
      parentId: uuidv4(),
      currentStep: 0,
      products: this.selectedProducts,
      name: this.additionalInfo.name || "Komposisjon #" + (this.compositionCollection.length + 1),
      toggleUserEditName: false,
      stepValidity: [],
      stepContainerValid: false,
      reloadKey: 0,
      openContainerId: null,
      loading: false,
    };
  },
  beforeMount() {
    this.stepValidity = Array.from(Array(this.steps.length), () => false);
  },
  mounted() {
    // Add all attached products directly to the choices
    this.stepData.items.forEach((product) => {
      const newProduct = {
        id: product.id,
        name: product.text,
        productId: product.productId,
        quantity: 1,
        parentStep: this.stepData.id,
      };

      this.addProductsUpdateIfExists(this.stepData.id, [newProduct]);
    });
  },
  methods: {
    /**
     * Method to update the product selection of a parent step
     *
     * @param {*} parentStep The ID of the step
     * @param {*} products The current products array
     */
    addProductsUpdateIfExists: function (parentStep, products) {
      // If there are any products provided
      if (products.length > 0) {
        // Iterate every product
        products.forEach((product) => {
          // Find the array index of current product
          let index = this.products.findIndex((p) => p.id === product.id && p.parentStep === product.parentStep);

          // If the product is found
          if (index != -1) {
            // Update the product data
            this.products[index] = product;
          }
          // If the product isn't found
          else {
            // Add it to the products collection
            this.products.push(product);
          }

          // Tell the parent component that the product selection is updated
          this.$emit("addProductUpdateIfExists", product);
        });
      }
      // If there are no products provided, which means we have to remove products
      else {
        console.error("addProductsUpdateIfExists was called in StepContainer.vue without any products");
      }
    },

    /**
     *
     * @param {*} product
     */
    addProductUpdateIfExists: function (product) {
      this.products = this.products.push(product);
      this.$emit("addProductUpdateIfExists", product);
    },

    /**
     *
     * @param {Array} product
     */
    removeProducts: function (products = []) {
      if (products.length < 1) {
        const productsToRemove = [...this.products];

        productsToRemove.forEach((product) => {
          this.$emit("removeProduct", product);
        });

        this.products = [];
      } else {
        products.forEach((product) => {
          this.products = this.products.filter((p) => p.id != product.id);
          this.$emit("removeProduct", product);
        });
      }
    },

    /**
     *
     * @param {*} stepIndex
     * @param {*} validity
     */
    updateStepValidity: function (stepIndex, validity) {
      this.$set(this.stepValidity, stepIndex, validity);
    },

    /**
     *
     */
    editName: function () {
      if (this.toggleUserEditName) {
        this.$emit("setAdditionalInfo", { name: this.name });
      }

      this.toggleUserEditName = !this.toggleUserEditName;
    },

    /**
     *
     */
    addCompositionUpdateIfExists: function () {
      const products = [...this.products];

      products.sort((a, b) => {
        if (a.productId < b.productId) {
          return -1;
        }
        if (a.productId > b.productId) {
          return 1;
        }
        return 0;
      });

      const composition = {
        id: this.id.slice(),
        name: this.name.slice(),
        products: products,
        quantity: 1,
        parentId: this.parentId.slice(),
        parentStep: this.stepData.id,
      };

      this.$emit("addCompositionUpdateIfExists", composition);

      this.$store.dispatch("storeComposition", composition);

      this.name = "Komposisjon #" + (this.compositionCollection.length + 1);
      this.$emit("resetCompositionInput");
      this.openContainerId = null;
      this.id = uuidv4();
      this.reloadKey++;
    },

    /**
     *
     * @param {*} id
     */
    removeComposition: function (id) {
      this.$emit("removeComposition", id);
      this.$store.dispatch("deleteComposition", id);
      this.showCompositionDeletionDialog = false;
    },

    /**
     *
     * @param {*} id
     */
    setComposition: function (id) {
      this.loading = true;

      this.$emit("restoreCompositionCache", id);

      const step = this.compositionCollection.find((c) => c.id === id);
      this.id = step.id.slice();
      this.name = step.name.slice();

      this.reloadKey++;
      setTimeout(() => (this.loading = false), 300);
    },

    removeCompositionChoices: function () {
      this.loading = true;
      this.id = uuidv4();
      this.name = "Komposisjon #" + (this.compositionCollection.length + 1);
      this.removeProducts();
      this.openContainerId = null;
      this.reloadKey++;
      setTimeout(() => (this.loading = false), 300);
    },

    calculateTotalQuantity: function (products) {
      return products.map((p) => p.quantity).reduce((total, quantity) => total + quantity, 0);
    },
  },
  computed: {
    steps: function () {
      if (this.stepData.steps?.length > 0) {
        return this.stepData.steps;
      } else if (this.stepData.productBuilderHiddenSteps?.length > 0) {
        return this.stepData.productBuilderHiddenSteps;
      } else {
        return [];
      }
    },
    changesHasBeenMade: function () {
      const compositionsExist = this.compositionCollection.length > 0;
      const nameSet = this.name != "Komposisjon #" + (this.compositionCollection.length + 1);
      const productsChosen = this.products.length > 0;

      return compositionsExist && (nameSet || productsChosen);
    },
  },
  watch: {
    stepValidity: {
      handler(val) {
        const stepsCurrentlyActive = this.steps.map((step) => {
          const productsWithFilter = step.items.filter((product) => product.filterItemIds.length > 0);

          if (productsWithFilter.length > 0) {
            for (let i = 0; i < productsWithFilter.length; i++) {
              // Iterate all filters on product
              for (let j = 0; j < productsWithFilter[i].filterItemIds.length; j++) {
                // If we can find a product with this id
                if (this.$store.getters.productExistInChoices(productsWithFilter[i].filterItemIds[j])) {
                  return step.id;
                }
              }
            }
          } else {
            return step.id;
          }
        });

        const compositionHasInvalidSteps =
          val.filter((v, i) => stepsCurrentlyActive[i] != undefined && v === false).length > 0;

        // If there is any compositions saved
        if (this.compositionCollection.length > 0) {
          this.$emit("stepValidityChanged", true);
          if (compositionHasInvalidSteps) {
            this.stepContainerValid = false;
          } else {
            this.stepContainerValid = true;
          }
        }

        // If there is one or more invalid steps
        else if (compositionHasInvalidSteps) {
          this.$emit("stepValidityChanged", false);
          this.stepContainerValid = false;
        }

        // Otherwise we assume everything is in order
        else {
          this.$emit("stepValidityChanged", true);
          this.stepContainerValid = true;
        }
      },
    },
    selectedProducts: {
      handler(products) {
        this.products = products;
      },
    },
  },
};
</script>

<style>
.canes-border {
  border: 2px solid var(--v-primary-base);
}

.center-icon > .v-alert__wrapper > .v-icon {
  margin: auto 1rem auto 0;
}
</style>

<template>
  <v-container>
    <!-- Header & Description -->
    <v-row v-if="this.stepData.header || this.stepData.description" class="mt-8">
      <v-col cols="12" lg="10" xl="8" class="d-flex flex-column">
        <h2 class="font-weight-light" v-if="this.stepData.header">{{ this.stepData.header }}</h2>
        <span v-if="this.stepData.description">{{ this.stepData.description }}</span>
      </v-col>
    </v-row>

    <!-- Main Card -->
    <v-card :key="reloadKey" class="my-5" style="position: relative">
      <v-fade-transition>
        <div
          v-if="loading"
          class="d-flex flex-column justify-center align-center background lighten-1"
          style="position: absolute; inset: 0; z-index: 999"
        >
          <v-progress-circular indeterminate :size="70" color="primary"></v-progress-circular>
          <span class="mt-5">Laster data</span>
        </div>
      </v-fade-transition>

      <!-- Title -->
      <v-card-title>
        <input
          v-if="toggleUserEditName"
          type="text"
          name="compositionName"
          :style="`min-width: 100px; width:100%; max-width: ${name.length}ch; font-size: 20px;`"
          v-model="name"
          class="pa-0 canes-border font-weight-medium ml-n1"
        />
        <span v-else>{{ name }}</span>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-icon
              role="button"
              class="pa-0 mx-2"
              :color="toggleUserEditName ? 'success' : null"
              small
              v-on="on"
              @click="editName()"
            >
              far fa-edit
            </v-icon>
          </template>
          <span>Endre navn på komposisjon</span>
        </v-tooltip></v-card-title
      >

      <!-- Subtitle -->
      <v-card-subtitle class="">
        <span>Navn på din komposisjon av produkter</span>
      </v-card-subtitle>

      <!-- Base Module -->
      <v-container class="d-flex justify-evenly">
        <product-tile
          v-for="product in stepData.items"
          :key="`stepContainer_product_${product.productId}`"
          :product="product"
          :amountSelection="false"
          :hideActions="stepData.items.length === 1"
        />
      </v-container>

      <v-divider></v-divider>

      <!-- Steps -->
      <container-step
        v-for="(step, index) in steps"
        :key="'container_step_' + step.id"
        :step="step"
        :selected-products="products.filter((p) => p.parentStep === step.id)"
        :display-divider="index != steps.length - 1"
        :open-override="step.id === openContainerId"
        @toggleOpenContainerId="(id) => (openContainerId = id)"
        @addProductsUpdateIfExists="({ parentStep, products }) => addProductsUpdateIfExists(parentStep, products)"
        @removeProduct="(product) => removeProducts([product])"
        @updateStepValidity="(validity) => updateStepValidity(index, validity)"
      >
      </container-step>

      <v-divider v-if="stepContainerValid || changesHasBeenMade"></v-divider>

      <!-- Composition Actions -->
      <v-expand-transition>
        <v-container v-if="stepContainerValid || changesHasBeenMade" class="d-flex justify-center pa-0">
          <!-- Remove Composition Choices Button -->
          <v-slide-x-reverse-transition group class="d-flex">
            <div :key="`compositionAction_remove`">
              <v-btn
                outlined
                rounded
                min-width="220"
                class="d-flex justify-space-between text-none primary--text ma-10"
                elevation="1"
                @click="removeCompositionChoices()"
              >
                Fjern valg
                <v-icon right>mdi-close-circle-outline</v-icon>
              </v-btn>
            </div>

            <!-- Store Composition Button -->
            <div :key="`compositionAction_save`">
              <v-btn
                v-if="stepContainerValid"
                rounded
                min-width="220"
                color="primary"
                class="d-flex justify-space-between text-none ma-10"
                elevation="1"
                @click="addCompositionUpdateIfExists"
              >
                Lagre komposisjon
                <v-icon right>mdi-content-save-outline</v-icon>
              </v-btn>
            </div>
          </v-slide-x-reverse-transition>
        </v-container>
      </v-expand-transition>
    </v-card>

    <!-- Step Description2 -->
    <v-row v-if="this.stepData.description2" class="mt-8">
      <v-col cols="12" lg="10" xl="8" class="mx-auto">
        <span v-html="this.stepData.description2"></span>
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-row
        v-if="compositionCollection.length > 0 && compositionCollection.some((c) => c.parentStep == stepData.id)"
        class="mt-10 mb-5"
      >
        <v-col cols="12" lg="10" xl="8" class="mx-auto">
          <div class="error--text d-flex align-center rounded" style="gap: 1rem">
            <v-icon color="error">mdi-information-outline</v-icon>
            <span>
              Vær oppmerksom på at nå som du har lagret en komposisjon, så vil ikke lenger produktene fra
              <strong>{{ name }}</strong> bli med videre i byggeren, med mindre denne komposisjonen også lagres.
            </span>
          </div>
        </v-col>
      </v-row>
    </v-expand-transition>

    <composition-collection
      :parentId="parentId"
      :compositionCollection="compositionCollection"
      @removeComposition="(id) => removeComposition(id)"
      @setComposition="(id) => setComposition(id)"
    />

    <!-- DEBUG -->
    <!-- <v-container class="text-left primary darken-2 white--text pa-0 my-12">
      <h4 class="pa-5 primary darken-4">StepContainer.vue</h4>
      <pre class="pa-5">Name: {{ name }}</pre>
      <pre class="pa-5">Products Length: {{ products.length }}</pre>
    </v-container> -->
  </v-container>
</template>
