<script>
import Hero from "@/components/Hero.vue";

export default {
  name: "Home",
  components: {
    Hero,
  },
};
</script>

<template>
  <v-container fill-height>
    <v-row justify="center" class="pb-5">
      <v-progress-circular
        v-if="!$store.state.builder.name || Object.keys($store.state.friggProductDetails).length === 0"
        :width="6"
        :size="100"
        class="my-5"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <template v-else>
        <Hero :title="$store.state.builder.label" :subtitle="$store.state.builder.description" />
        <v-btn
          to="/guide"
          color="primary"
          rounded
          x-large
          min-width="300"
          class="d-flex justify-space-between text-none mt-4 mb-16"
          elevation="0"
        >
          {{ $store.state.projectName ? `Fortsett med ${$store.state.projectName}` : "Start bygger" }}
          <v-icon right> fal fa-arrow-right </v-icon>
        </v-btn>
      </template>
    </v-row>
    <v-row>
      <!-- DEBUG -->
      <!-- <pre class="text-left primary darken-2 white--text pa-5 my-12 mw">{{
        Object.keys($store.state.friggProductDetails).length === 0
      }}</pre> -->
    </v-row>
  </v-container>
</template>
